import { UserAuthAPI } from '@warnerconnect/library';

export async function loginUser(dispatch, userToken) {

	let userData = {
		accessToken: userToken.getToken(),
		user: {
			id: userToken.getUser().getId(),
			email: userToken.getUser().getEmail(),
			roles: userToken.getUser().getRoles(),
			name: userToken.getUser().getName()
		}
	};

	localStorage.setItem('currentUser', JSON.stringify(userData));

	try {
		dispatch({ type: 'LOGIN_SUCCESS', payload: userData });

		return true;
	}
	catch (error)
	{
		dispatch({ type: 'LOGIN_ERROR', error: error });
	}
}

export async function logout(dispatch) {
	dispatch({ type: 'LOGOUT' });

	localStorage.removeItem('currentUser');
}


export async function changeUserTeam(dispatch, team) {
	localStorage.setItem('currentTeam', JSON.stringify(team));

	dispatch({ type: 'CHANGE_TEAM', team: team });

}
