import { Fragment, useState } from 'react';
import { useSnackbar } from "notistack";

import Image from 'mui-image'
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import dateFormat, { masks } from "dateformat";
import { Link, useHistory } from "react-router-dom";
import MLink from '@mui/material/Link';

import NoImage from './../../assets/img/no-image.png';
import ContextMenu from './ContextMenu';
import ModalDialogue from './ModalDialogue';
import { productFamilies, supportedIntegrations } from '../Utilities/Services';

import {
    OpenInNew as OpenInNewIcon, 
    Edit as EditIcon,
    ShowChart as ShowChartIcon,
    Visibility as VisibilityIcon,
    ContentCopy as ContentCopyIcon,
    Delete as DeleteIcon
} from '@mui/icons-material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpotify, faApple, faDeezer, faYoutube, faAmazon, faVk, faFacebook, faTiktok } from '@fortawesome/free-brands-svg-icons'
import { Chip, Button } from '@mui/material';
import { Box } from '@mui/system';
import User from './User';

export default function CampaignListSingle(props) {
    const [deleteModal, setDeleteModal] = useState(false);
    const [copyModal, setCopyModal] = useState(false);

    const toggleDeleteModal = event => {
        setDeleteModal(!deleteModal);
    }

    const toggleCopyModal = event => {
        setCopyModal(!copyModal);
    }

    let image = props.data.getDataValue(null, null, 'theme.image.url');
    let globalLocale = props.data.getGlobalLocale();
    let integrations = globalLocale.getIntegrations() || [];
    let integrationsHTML = [];
    let integrationsList = [];

    integrations.forEach( integration => {
        let supportedIntegrationData = supportedIntegrations.find(supportedIntegrationsSingle => supportedIntegrationsSingle.id === integration);

        if( !supportedIntegrationData )
        {
            return;
        }

        let productFamilyData = productFamilies.find(productFamiliesSingle => productFamiliesSingle.id === supportedIntegrationData.productFamily);


        let icon = productFamilyData.icon;
        let label = supportedIntegrationData.label;

        if( !integrationsList.includes(productFamilyData.id) )
        {
            integrationsList.push(productFamilyData.id);
            integrationsHTML.push(<span key={integration}>{icon}&nbsp;&nbsp;</span>);

        }
    });

    let campaignTitle = props.data.getName() || <em>Undefined</em>;

    let subTitle = [];

    let type = 'Follow';
    var team = '';

    if( globalLocale.getTypes().includes('presave') || globalLocale.getTypes().includes('autoprerelease') )
    {
        if( globalLocale.getArtist() && globalLocale.getConfigurationValue('general.context') )
        {
            campaignTitle = <>{globalLocale.getArtist()}: {globalLocale.getConfigurationValue('general.context')}</>;
        }

        type = 'Pre-save';
    }
    else if( globalLocale.getTypes().includes('form') )
    {
        type = 'Form';
    }
    else if( globalLocale.getTypes().includes('save') )
    {
        type = 'Save to Library';
    }
    else if( globalLocale.getTypes().includes('contentunlock') )
    {
        type = 'Content Unlock';
    }
    else if( globalLocale.getTypes().includes('profile') )
    {
        type = 'Listening history';
    }
    else if( globalLocale.getTypes().includes('streamtoenter') )
    {
        type = 'Stream to Enter';
    }
    else if( globalLocale.getTypes().includes('redeemabledownloadcode') )
    {
        type = 'Redeemable Download Code';
    }

    subTitle.push(type);

    if( team )
    {
        subTitle.push(team);
    }

    if( integrationsHTML && ( integrationsHTML.length > 0 ) )
    {
        subTitle.push(integrationsHTML);
    }

    return (
        <TableRow key={props.data.getId()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell sx={{width: '5%', paddingRight: 0}} component="th" scope="row">
                <Image sx={{maxWidth: '40px', borderRadius: '3px'}} src={image || NoImage} />
            </TableCell>
            <TableCell sx={{width: '53%', paddingRight: 0}} component="th" scope="row">
                <Typography variant="body1" sx={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '500px', display: 'inline-block'}}><MLink component={Link} to={`/campaigns/${props.data.getId()}/edit`}>{campaignTitle}</MLink></Typography>
                <Typography variant="subtitle2">
                    {
                        subTitle.map( (subTitle, index) => {
                            return <Fragment key={index}>{ (index > 0) && <>&nbsp; &middot; &nbsp;</> }{subTitle}</Fragment>;
                        })
                    }
                </Typography>
            </TableCell>
            <TableCell sx={{width: '10%', paddingRight: 0}}>
                <Typography variant="subtitle2">{props.data.getTotalEntrants() ? props.data.getTotalEntrants().toLocaleString() : 0}</Typography>
            </TableCell>
            <TableCell sx={{width: '13%', paddingRight: 0}}>
                <Typography variant="subtitle2">{props.data.getDateFinish() ? dateFormat(props.data.getDateFinish(), "mmm dS yyyy") : ''}</Typography>
            </TableCell>
                            
            <TableCell sx={{width: '10%', paddingRight: 0}}>
                {props.data.getUser() && <User id={props.data.getUser()} />}
            </TableCell>

            <TableCell sx={{width: '9%', paddingRight: 0}}>
                <ModalDialogue showFooter={false} open={deleteModal} onClose={toggleDeleteModal}>
                    <Box sx={{px: 2, pt: 2, pb: 1}}>

                        <Typography mb={2} variant="h4">Delete Campaign</Typography>
                        <Typography variant="body1">Are you sure you want to delete this campaign? This task cannot be undone.</Typography>

                        <Box sx={{textAlign: 'right'}}>
                            <Button
                                onClick={toggleDeleteModal}
                                sx={{mt: 2, ml: 2}}
                            >
                                Cancel
                            </Button>

                            <Button
                                onClick={() => {
                                    if( props.onDelete )
                                    {
                                        props.onDelete(props.data.getId()).then(deleteResponse => {
                                            toggleDeleteModal();
                                        });
                                    }
                                }}
                                sx={{mt: 2, ml: 2}
                            }>
                                Delete
                            </Button>
                        </Box>
   
                    </Box>
                </ModalDialogue>
                
                <ModalDialogue showFooter={false} open={copyModal} onClose={toggleCopyModal}>
                    <Box sx={{px: 2, pt: 2, pb: 1}}>

                        <Typography mb={2} variant="h4">Copy Campaign</Typography>
                        <Typography variant="body1">Are you sure you want to create a copy of this campaign?</Typography>

                        <Box sx={{textAlign: 'right'}}>
                            <Button
                                onClick={toggleCopyModal}
                                sx={{mt: 2, ml: 2}}
                            >
                                Cancel
                            </Button>

                            <Button
                                onClick={() => {
                                    if( props.onCopy )
                                    {
                                        props.onCopy(props.data.getId()).then(copyResponse => {
                                            toggleCopyModal();
                                        });
                                    }
                                }}
                                sx={{mt: 2, ml: 2}
                            }>
                                Copy
                            </Button>
                        </Box>
   
                    </Box>
                </ModalDialogue>

                <ContextMenu
                    options={[
                        {icon: <EditIcon />, label: 'Edit', to: '/campaigns/'+props.data.getId()+'/edit'},
                        {icon: <ShowChartIcon />, label: 'Stats', to: '/campaigns/'+props.data.getId()+'/stats'},
                        {icon: <ContentCopyIcon />, label: 'Copy', onClick: toggleCopyModal},
                        {icon: <DeleteIcon />, label: 'Delete', onClick: toggleDeleteModal},
                        {divider: true},
                        {icon: <VisibilityIcon />, label: 'View Landing Page', to: `${process.env.REACT_APP_FRONTEND_URL}/app/${props.data.getId()}${props.data.getUrlSlug() ? '/'+props.data.getUrlSlug() : ''}`, annotation: <OpenInNewIcon />, external: true}
                    ]}
                />
            </TableCell>
        </TableRow>
    );
}