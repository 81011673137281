import * as React from 'react';
import Box from '@mui/material/Box';

export default function HeroButtonGroup(props) {
/*
  let _props = Object.assign({}, props);
  if( !_props.sx ) _props.sx = {};

  _props = Object.assign({sx: {height: '150px', width: '150px', textAlign: 'left', verticalAlign: 'top', alignItems: 'flex-start', padding: '20px', boxShadow: '0 6px #cfdadd', marginBottom: '6px', '.MuiSvgIcon-root': { position: 'absolute', width: '1.5em', height: '1.5em', left: '20px', bottom: '20px' }}}, _props.sx);

  _props.variant = 'contained';
  _props.color = 'secondary';
*/
  return (
    <Box sx={{position: 'absolute', right: '0', bottom: '-2.815rem', zIndex: 500}}>{props.children}</Box>
  );
}