import * as React from 'react';

import Grid from '@mui/material/Grid';

export default function FieldContainer(props) {
  
  let _props = {...props};

  _props.xs = _props.xs || 6;
  
  return (
    <Grid item {..._props}>
      {props.children}
    </Grid>
  );
}