import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {
    CloudUpload as CloudUploadIcon
} from '@mui/icons-material';

import { AcquisitionAPI } from '@warnerconnect/library';

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import PlaceholderImage from '../../../../assets/img/image-placeholder.png'
import { useAuthState } from '../../../../Context';

export default function ImageUpload(props) {
  const [image, setImage] = useState(props.value ? ( props.value.substr(0, 4) === 'http' ? props.value : 'https://cdn.campaigns.wmgconnect.com/'+props.value.replaceAll('tpl/uploads', 'uploads') ) : '');
  const [loading, setLoading] = useState(false);

  const userDetails = useAuthState();

  const {getRootProps, getInputProps} = useDropzone({
    multiple: false,
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setLoading(true);


      setImage(URL.createObjectURL(acceptedFiles[0]));

      let formData = new FormData();           
      formData.append("file", acceptedFiles[0]);

      var acquisitionAPI = new AcquisitionAPI();
      acquisitionAPI.setBearerToken(userDetails.accessToken);

      acquisitionAPI.uploadImage(formData).then(fileResponse => {
          if( props.onChange )
          {
              props.onChange(fileResponse.file.url);
          }

          setLoading(false);
      });
    }
    
  });

  var height = 341;

  if( props.size )
  {
    if( props.size === 'small' )
    {
      height = 140;
    }
  }

  return (
    <Card variant="outlined">
      {
          image ? 
            <Container sx={{height: height, backgroundPosition: 'center center', backgroundImage: `url(${image})`, backgroundSize: 'contain', backgroundColor: '#f4f4f4', backgroundRepeat: 'no-repeat'}}></Container>
          :
            <Container sx={{height: height, backgroundPosition: 'center center', backgroundImage: `url(${PlaceholderImage})`, backgroundSize: 'auto', backgroundColor: '#f4f4f4', backgroundRepeat: 'no-repeat'}}></Container>
      }
      <CardActions disableSpacing >

        <LoadingButton
              {...getRootProps({className: ''})}
              size="medium"
              loading={loading}
              loadingPosition="start"
              startIcon={<CloudUploadIcon />}
          >
              <input {...getInputProps()} />
              {loading ? 'Uploading' : image ? 'Change Image' : 'Add Image'}
          </LoadingButton>
          {
            image &&
            <Button
              color="error"
              size="medium"
              sx={{ml: 2}}
              startIcon={<DeleteIcon />}
              onClick={() => {
                setImage(null);

                if( props.onChange )
                {
                    props.onChange(null);
                }
              }}
            >
              Remove Image
            </Button>
          }
      </CardActions>
    </Card>
    );
}
