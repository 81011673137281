import React, { Fragment, useState } from 'react';

import { TablePagination, Chip, Box, Button, ButtonGroup, Typography, CircularProgress, Stack, Divider, Paper, TextField, Tooltip, Link as MLink, InputLabel, Grid, Autocomplete, NativeSelect, Select, MenuItem, InputAdornment, List, ListItem, ListItemText, Checkbox, IconButton, ListItemIcon, ListItemButton } from '@mui/material';

import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Info as InfoIcon
} from '@mui/icons-material';
import ContextMenu from '../../ContextMenu';

import Flag from 'react-world-flags'

export default function RecordLabelSelector(props) {
    const [formFieldData, setFormFieldData] = useState(props.value);

    const handleChange = (event, newValue) => {
        setFormFieldData(newValue);

        if( props.onChange )
        {
            props.onChange(event, newValue);
        }
    }

    var _props = {
        name: props.name,
        options: props.options,
        onChange: handleChange,
        value: formFieldData
    };

    if( !_props.isOptionEqualToValue ) _props.isOptionEqualToValue = (option, value) => option.id === value.id;

    return (
        <Autocomplete
            multiple
            {..._props}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
                <Box key={option.id} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Checkbox
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.label}
                </Box>
            )}
            freeSolo
            disableCloseOnSelect
            renderInput={(params) => (
                <TextField
                    {...params}
                    fullWidth
                    placeholder={!formFieldData || ( formFieldData.length === 0 ) ? 'All' : ''}
                />
            )}
        />
    );
}