import * as React from 'react';
import ContentBox from './ContentBox';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import TeamUserListSingle from './TeamUserListSingle';

export default function TeamUserList(props) {
  return (
    <TableContainer>
        <ContentBox sx={{p: 0}}>
            <Table>
                <TableBody>
                    { ( props.data.length > 0 ) ?
                        props.data.map((row) => {
                            var userSingleProps = {
                                key: row.getId(),
                                data: row
                            };

                            if( props.onDelete )
                            {
                                userSingleProps['onDelete'] = props.onDelete;
                            }

                            if( props.onCopy )
                            {
                                userSingleProps['onCopy'] = props.onCopy;
                            }

                            return (
                                <TeamUserListSingle {...userSingleProps} />
                            )
                        }
                    )
                    :
                    <TableRow>
                        <TableCell colSpan={7} sx={{width: '40%', paddingRight: 0, textAlign: 'center'}} component="th" scope="row">
                            <Typography variant="body1">{props.noRecordsLabel || 'No results to display'}</Typography>
                        </TableCell>
                    </TableRow>
                    }
                </TableBody>
            </Table>
        </ContentBox>
    </TableContainer>
  );
}