import * as React from 'react';
import ContentBox from './ContentBox';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';

import CampaignListSingle from './CampaignListSingle';

export default function Accordion(props) {
    var _props = {...props}
    delete _props.subHeader;
    _props.sx = _props.sx || {};
    _props.sx.mt = _props.sx.mt || 2;
    _props.sx.mb = _props.sx.mb || 3;

    return (
        <>
            <Box sx={{pt: 6}}>
                <Typography variant="h4">{props.header}</Typography>
                { props.subHeader && <Typography sx={{mt: 1}} variant="body1">{props.subHeader}</Typography> }
            </Box>

            <ContentBox {..._props}>
                {props.children}
            </ContentBox>
        </>
    );
}