import * as React from 'react';
import ContentBox from './ContentBox';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function TableBarChart(props) {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{width: '5%'}}>#</TableCell>
                        { ( props.options && props.options.imageLabel ) &&
                            <>
                                <TableCell sx={{width: '10%'}}>{ props.options.imageLabel }</TableCell>
                            </>
                        }
                        <TableCell sx={{width: ( props.options && props.options.imageLabel ) ? '30%' : '40%'}}>{ props.options && props.options.label ? props.options.label : '' }</TableCell>
                        <TableCell sx={{width: '45%'}}></TableCell>
                        <TableCell sx={{width: '10%'}}>Total</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {props.data.map((row) => (
                        <TableRow key={row.index}>
                            <TableCell>{row.index}</TableCell>
                            { ( props.options && props.options.imageLabel ) &&
                                <TableCell>{row.image}</TableCell>
                            }
                            <TableCell>{row.name}</TableCell>
                            <TableCell>
                                <Box sx={{height: '3px', backgroundColor: 'rgb(232, 232, 232)'}}>
                                    <Box sx={{height: '3px', backgroundColor: 'rgb(68, 188, 228)', position: 'relative', width: row.percentage+'%'}}>
                                        <Box sx={{height: '9px', width: '9px', backgroundColor: 'rgb(68, 188, 228)', borderRadius: '100%', position: 'absolute', top: '-3px', right: '-3px'}} />
                                        <Box sx={{height: '9px', width: '9px', backgroundColor: 'rgb(68, 188, 228)', borderRadius: '100%', position: 'absolute', top: '-3px', left: '-3px'}} />
                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell>{row.total.toLocaleString()}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}