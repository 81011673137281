export const formReducer = (state, action) => {

    switch(action.type){
        case 'reset':
            return {};
        default:
            return {...state,
                    [action.name]: action.value
                   };
    };
}