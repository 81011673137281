import React, { useState, useEffect, useReducer } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { EngagementAPI, EngagementRepository, UserRepository } from '@warnerconnect/library';

import { TablePagination, Button, Typography, CircularProgress, Link as MLink, Grid, Paper, MenuList, MenuItem, ListItemText, ListItemIcon, Checkbox, Box, ToggleButton, ToggleButtonGroup, Card, CardHeader, CardMedia, CardContent, CardActions, IconButton, Alert, InputLabel, TextField, Select, OutlinedInput, Chip, RadioGroup, FormControlLabel, Radio, Tooltip } from '@mui/material';

import parse, { domToReact } from 'html-react-parser';

import {
    AddBox as AddBoxIcon,
    AddCircleOutline as AddCircleOutlineIcon,
    InfoOutlined as InfoOutlinedIcon,
    Save as SaveIcon,
    Star as StarIcon,
    StarBorder as StarBorderIcon,
    Share as ShareIcon,
    Info as InfoIcon
} from '@mui/icons-material';

import { useAuthDispatch, logout, useAuthState, changeUserTeam } from '../../../Context';

import { formReducer } from '../../../Context/formReducer';
import { useSnackbar } from "notistack";

import Image from 'mui-image'

import linkfireLogo from '../../../assets/media/home/icon.linkfire.png';
import fptLogo from '../../../assets/media/home/logo.firepit.png';
import wmcLogo from '../../../assets/media/home/icon.wmc.svg';
import salesforceLogo from '../../../assets/media/home/icon.salesforce.png';
import brandonLogo from '../../../assets/media/home/icon.brandon.png';
import communityLogo from '../../../assets/media/home/icon.community.png';
import wmgLogo from '../../../assets/media/home/icon.wmg.svg';
import metaLogo from '../../../assets/media/home/icon.meta.png';
import youtubeLogo from '../../../assets/media/home/icon.youtube.png';
import snapLogo from '../../../assets/media/home/icon.snap.svg';
import twitterLogo from '../../../assets/media/home/icon.twitter.png';

import linkfireProductPreRelease from '../../../assets/media/home/product.linkfire.release.jpg';

import wmcProductFollow from '../../../assets/media/home/product.wmc.follow.jpg';
import wmcProductSave from '../../../assets/media/home/product.wmc.save.jpg';
import wmcProductPreSave from '../../../assets/media/home/product.wmc.presave.jpg';
import wmcProductUnlock from '../../../assets/media/home/product.wmc.unlock.jpg';
import wmcProductForm from '../../../assets/media/home/product.wmc.form.jpg';
import wmcProductRedeemableDownload from '../../../assets/media/home/product.wmc.redeemablecode.jpg';

import Loader from '../../Elements/Loader';
import CampaignList from '../../Elements/CampaignList';
import useQuery from '../../Utilities/useQuery';
import useTitle from '../../Utilities/useTitle';
import ContentBox from '../../Elements/ContentBox';
import PageContainer from '../../Elements/PageContainer';
import HeroText from '../../Elements/HeroText';
import Hero from '../../Elements/Hero';
import ModalDialogue from '../../Elements/ModalDialogue';
import HeroButtonGroup from '../../Elements/HeroButtonGroup';
import HeroButton from '../../Elements/HeroButton';
import TableBarChart from '../../Elements/TableBarChart';
import BarChart from '../../Elements/Chart/BarChart';
import LineChart from '../../Elements/Chart/LineChart';

import CampaignsHeroImage from '../../../assets/media/hero/campaigns.jpg';
import Fieldset from '../../Elements/Forms/Fieldset';
import FieldContainer from '../../Elements/Forms/FieldContainer';
import { LoadingButton } from '@mui/lab';
import RichTextEditor from '../../Elements/Forms/Fields/RichTextEditor';
import ImageUpload from '../../Elements/Forms/Fields/ImageUpload';
import LinkList from '../../Elements/Forms/Fields/LinkList';

const acquisitionSupportTypes = [
    {name: 'Self Service', key: 'self', selected: false},
    {name: 'Support Required', key: 'support', selected: false}
];

const acquisitionGoalTypes = [
    {
        name: 'Grow a fan base',
        key: 'acquisition',
        selected: false,
        tooltip: <>
        <Typography sx={{fontSize: '0.8rem'}} paragraph>Find answers to questions about acquisition, such as:</Typography>
        <Typography sx={{fontSize: '0.8rem', mb: 0}} paragraph>• How do I collect first party data?<br />• How do I identify the artist audience?<br />• How do I increase followers for my artist?</Typography>
        </>
    },
    {
        name: 'Increase streams',
        key: 'streaming',
        selected: false,
        tooltip: <>
        <Typography sx={{fontSize: '0.8rem'}} paragraph>Find answers to questions about streaming, such as:</Typography>
        <Typography sx={{fontSize: '0.8rem', mb: 0}} paragraph>• What tools can I use to promote track and artists on streaming platforms?<br />• How can I tell my audience to listen to an album?<br />• What products should I use to build 'out now' campaigns?</Typography>
        </>
    },
    {
        name: 'Sell physical products',
        key: 'ecommerce',
        selected: false,
        tooltip: <>
        <Typography sx={{fontSize: '0.8rem'}} paragraph>Find answers to questions about physical products, such as:</Typography>
        <Typography sx={{fontSize: '0.8rem', mb: 0}} paragraph>• What kind of campaigns can I run to drive incremental sales?<br />• Which tools can help me understand who to target for e-commerce campaigns?<br />• How can I improve my e-commerce and retail strategy?</Typography>
        </>
    },
    {
        name: 'Engage your community',
        key: 'engagement',
        selected: false,
        tooltip: <>
        <Typography sx={{fontSize: '0.8rem'}} paragraph>Find answers to questions about engagement, such as:</Typography>
        <Typography sx={{fontSize: '0.8rem', mb: 0}} paragraph>• What are some ways I can keep my audience engaged?<br />• How can I segment my audience to engage them with personalised messages?<br />• What are the tools to communicate with my audience?</Typography>
        </>
    },
    {
        name: 'Understand performance',
        key: 'analysis',
        selected: false,
        tooltip: <>
        <Typography sx={{fontSize: '0.8rem'}} paragraph>Find answers to questions about analytics and reporting, such as:</Typography>
        <Typography sx={{fontSize: '0.8rem', mb: 0}} paragraph>• How many active fans does my artist have in our database at the moment?<br />• How did my previous campaign perform?<br />• What is my artists audience across platforms?</Typography>
        </>
    },
    {
        name: 'Maximise visibility',
        key: 'seo-ux',
        selected: false,
        tooltip: <>
        <Typography sx={{fontSize: '0.8rem'}} paragraph>Find answers to questions about awareness and optimisation, such as:</Typography>
        <Typography sx={{fontSize: '0.8rem', mb: 0}} paragraph>• How can I amplify my campaigns' reach?<br />• How do I improve search positioning for my websites?<br />• How can I optimise visibility for my campaigns?</Typography>
        </>
    }
];

const acquisitionChannelTypes = [
    {name: 'Email/SMS', key: 'sms', selected: false},
    {name: 'Social Media', key: 'social-media', selected: false},
    {name: 'Web', key: 'web', selected: false},
    {name: 'Touring', key: 'touring', selected: false},
    {name: 'Paid Channels', key: 'paid-channels', selected: false}
];

const groupData = [
    {
        name: 'Social Commerce',
        subTitle: 'Multiple platforms available',
        description: <>
            <Typography paragraph variant="body2" color="text.primary">Promote your artist's products through their social media channels including Facebook, Instagram, Snap, Twitter, and YouTube.</Typography>
            <Typography paragraph variant="body2" color="text.primary">This product includes more platforms. Expand to see all.</Typography>
        </>
    },
    {
        name: 'Social Messaging',
        subTitle: 'Multiple platforms available',
        description: <>
            <Typography paragraph variant="body2" color="text.primary">Promote your artist through automated messenger bots on Facebook and Instagram that drive meaningful fan engagement.</Typography>
            <Typography paragraph variant="body2" color="text.primary">This product includes more platforms. Expand to see all.</Typography>
        </>
    },
    {
        name: 'Email Marketing',
        subTitle: 'Multiple programs available',
        description: <>
            <Typography paragraph variant="body2" color="text.primary">Build, grow and engage your artist's mailing list database, developing the most effective ways to reach that audience. </Typography>
            <Typography paragraph variant="body2" color="text.primary">This product includes programs. Expand to see all.</Typography>
        </>
    },
    {
        name: 'Smart Links',
        subTitle: 'Multiple programs available',
        description: <>
            <Typography paragraph variant="body2" color="text.primary">Utlise smart links and codes that track in depth insights and performance whilst promoting your artist's offerings. </Typography>
            <Typography paragraph variant="body2" color="text.primary">This product includes programs. Expand to see all.</Typography>
        </>
    },
    {
        name: 'Pre-Save',
        subTitle: 'Multiple platforms available',
        description: <>
            <Typography paragraph variant="body2" color="text.primary">Build awareness and data acquisition in the lead up to your release with presaves. </Typography>
            <Typography paragraph variant="body2" color="text.primary">This product includes more platforms. Expand to see all.</Typography>
        </>
    },
    {
        name: 'Content Unlock',
        subTitle: 'Multiple platforms available',
        description: <>
            <Typography paragraph variant="body2" color="text.primary">Reward fans with exclusive unlockable content when they complete actions for your artist.</Typography>
            <Typography paragraph variant="body2" color="text.primary">This product includes more platforms. Expand to see all.</Typography>
        </>
    },
    {
        name: 'Advertising campaigns',
        subTitle: 'Multiple platforms available',
        description: <>
            <Typography paragraph variant="body2" color="text.primary">Amplify your artist through central ad hoc media credits across Facebook and TikTok.</Typography>
            <Typography paragraph variant="body2" color="text.primary">This product includes more platforms. Expand to see all.</Typography>
        </>
    }
];

const HomeDashboard = () => {
    const userDetails = useAuthState()
    const location = useLocation();
    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();

    const [userFavorites, setUserFavorites] = useState(null);
    const [userFavoritesOriginal, setUserFavoritesOriginal] = useState(null);

    const [productCreateModalShow, setProductCreateModalShow] = useState(false);
    const [productCreateData, setProductCreateData] = useReducer(formReducer, {supportLevel: 'Support Required', data: {}});

    const [userAdminAccess, setUserAdminAccess] = useState();
    const [userAdminAccessLoading, setUserAdminAccessLoading] = useState(true);

    const [groupModalShow, setGroupModalShow] = useState(null);
    const [productModalShow, setProductModalShow] = useState(null);
    const [productData, setProductData] = useState(null);
    const [productDataSorted, setProductDataSorted] = useState(null);
    const [groupDataSorted, setGroupDataSorted] = useState(null);

    const [productDataEvents, setProductDataEvents] = useState(0);

    const [filterData, setFilterData] = useState({
        support: acquisitionSupportTypes,
        goals: acquisitionGoalTypes,
        channels: acquisitionChannelTypes,
    });

    const [locationData, setLocationData] = useState([]);

    const toggleFilter = (type, index) => (event) => {
        var filterDataNew = {...filterData};
        filterDataNew[type][index]['selected'] = filterDataNew[type][index]['selected'] === true ? false : true;

        setFilterData(filterDataNew);
    }

    useTitle('Welcome');

    const engagementRepository = new EngagementRepository();
    const userRepository = new UserRepository();
    engagementRepository.setBearerToken(userDetails.accessToken);
    userRepository.setBearerToken(userDetails.accessToken);

    var selectedSupport = [];
    var selectedGoals = [];
    var selectedChannels = [];
        
    filterData.support.filter(item => item.selected).forEach( item => selectedSupport.push(item.name) );
    filterData.goals.filter(item => item.selected).forEach( item => selectedGoals.push(item.name) );
    filterData.channels.filter(item => item.selected).forEach( item => selectedChannels.push(item.name) );

    let buttonStyle = {
        justifyContent: 'flex-start',
        textAlign: 'left',
        alignItems: 'top',
        alignItems: 'start',
        flexDirection: 'column',
        borderWidth: '2px',
        borderColor: '#ffffff',
        '&.Mui-selected': {
            backgroundColor: '#ffffff',
        }
    };

    let buttonStyleTop = {...buttonStyle,
        backgroundColor: '#ffffff',
        borderColor: '#ffffff',
        '&.Mui-selected': {
            backgroundColor: '#e0f4fb',
        }
    };

    let cardheaderStyle = {
        '& .MuiTypography-h5': {
            fontSize: '1rem',
        }
    }

    let containerStyle = {
    };

    const refreshProductData = event => {
        setProductDataEvents(productDataEvents + 1);
    }

    useEffect(() => {
            userRepository.getUserPermission(userDetails.user.id, 'audienceSuiteAdmin')
                .then( userPermission => {
                    setUserAdminAccess(userPermission);
                    setUserAdminAccessLoading(false);
                })
                // User doesn't have this permission
                .catch(error => {
                    setUserAdminAccessLoading(false);
                });
    }, []);

    useEffect(() => {
        if( userDetails && userDetails.user )
        {
            userRepository.getUserData(userDetails.user.id, 'favoriteProducts')
                .then(userData => {
                    if( userData.getValue() )
                    {
                        let userProductFavorites = userData.getValue() ? JSON.parse(userData.getValue()) : null;
                        setUserFavorites(userProductFavorites);
                        setUserFavoritesOriginal(userProductFavorites);
                        sortProducts();
                    }
                })
                .catch(e => {

                });
        }

        engagementRepository.getProducts({limit: 10000, include: ['productGroup']}).then(products => {
            setProductData(products);
        });
    }, [productDataEvents]);

    useEffect(() => {
        sortProducts();
    }, [productData, userFavoritesOriginal, filterData]);

    useEffect(() => {
        let locationParts = location.pathname.split('/');

        setLocationData(locationParts);
    }, [location]);

    useEffect(() => {
        
        if( locationData && locationData[1] && ( locationData[2] === 'products' ) && locationData[3] )
        {
            setProductModalShow(parseInt(locationData[3]));
        }
        else
        {
            setProductModalShow(null);
        }

    }, [locationData]);

    const setProductCreateDataSingle = (name, value) => {
        setProductCreateData({ name: name, value: value});
    };

    const sortProducts = (sample = null) => {
        if( !productData || !productData.getItems() )
        {
            return null;
        }

        let sortedProductData = [...productData.getItems()];
        let sortedGroupData = [];

        if( userFavorites )
        {
            sortedProductData = sortedProductData.sort( (productA, productB) => {
                if( !userFavorites )
                {
                    return true;
                }

                return userFavorites.includes(productB.getId()) - userFavorites.includes(productA.getId());
            });
        }
        
        sortedProductData = sortedProductData.filter( (product) => {
            var foundSupport = [product.getSupportLevel()].some(r => selectedSupport.includes(r));
            var foundGoals = product.getGoals().some(r => selectedGoals.includes(r));
            var foundChannels = product.getChannels().some(r => selectedChannels.includes(r));

            if( ( selectedSupport.length > 0 ) && ( foundSupport === false ) )
            {
                return false;
            }

            if( ( selectedGoals.length > 0 ) && ( foundGoals === false ) )
            {
                return false;
            }

            if( ( selectedChannels.length > 0 ) && ( foundChannels === false ) )
            {
                return false;
            }

            let productGroupSingle = null;

            if( product.getGroupName() )
            {
                productGroupSingle = sortedGroupData.find(groupSingle => groupSingle.name === product.getGroupName());

                if( !productGroupSingle )
                {
                    productGroupSingle = {
                        name: product.getGroupName(),
                        products: []
                    }

                    sortedGroupData.push(productGroupSingle);
                }

                productGroupSingle.products.push(product);

                return false;
            }

            return true;
        });

        setGroupDataSorted(sortedGroupData);
        setProductDataSorted(sortedProductData);
    };

    const handleProductCreateData = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setProductCreateDataSingle(
            event.target.name,
            isCheckbox ? event.target.checked : event.target.value
        );
    }

    const handleProductCreateSubmit = event => {
        if( productCreateData.id )
        {
            let productUpdateData = {...productCreateData};
            delete productUpdateData.id;

            engagementRepository.updateProduct(productCreateData.id, productUpdateData).then(product => {
                refreshProductData();

                enqueueSnackbar("Product updated", {
                    variant: 'success'
                });
            });    
        }
        else
        {
            engagementRepository.createProduct(productCreateData).then(product => {
                refreshProductData();

                setProductCreateDataSingle('id', product.getId());

                enqueueSnackbar("Product added", {
                    variant: 'success'
                });
            });
        }

    }

    const handleFavoriteToggle = (productId) => {
        let newUserFavorites = userFavorites ? [...userFavorites] : [];

        let existingFavoriteIndex = newUserFavorites.indexOf(productId);

        if( existingFavoriteIndex >= 0 )
        {
            enqueueSnackbar("Favorite removed", {
                variant: 'success'
            });

            newUserFavorites.splice(existingFavoriteIndex, 1);
        }
        else
        {
            enqueueSnackbar("Favorite added", {
                variant: 'success'
            });

            newUserFavorites.push(productId);
        }

        if( newUserFavorites.length === 0 )
        {
            newUserFavorites = null;
        }

        userRepository.putUserData(userDetails.user.id, 'favoriteProducts', newUserFavorites ? JSON.stringify(newUserFavorites) : null);

        setUserFavorites(newUserFavorites);
    };

    // Needs to be refactored
    const formatHTML = (value) => {
        let options = {
            replace: function(domNode) {
                if (domNode.type === 'tag' && domNode.name === 'p' ) {
                    return <Typography variant="body2" color="text.primary">{domToReact(domNode.children, options)}</Typography>;
                }
                else if (domNode.type === 'tag' && domNode.name === 'a' ) {
                    return <MLink target="_blank" href={domNode.attribs.href.includes('@') ? 'mailto:'+domNode.attribs.href : domNode.attribs.href}>{domToReact(domNode.children, options)}</MLink>;
                }
                else if (domNode.type === 'tag' && domNode.name === 'li' ) {
                    return <li><Typography variant="body2" color="text.primary">{domToReact(domNode.children, options)}</Typography></li>;
                }
            }
        };

        return parse(value, options);
    };

    return (
        <>
            <ModalDialogue backdropClick={true} showFooter={false} open={productCreateModalShow} size="large" onClose={() => { setProductCreateModalShow(false); setProductCreateData({type: 'reset'}); }}>
                <Box sx={{px: 2, pt: 2, pb: 1}}>
                    <Typography variant="h4">{productCreateData.id ? 'Edit Product' : 'Add New Product'}</Typography>                    
                </Box>

                <Fieldset>
                    <FieldContainer xs={12}>
                        <InputLabel shrink>Name</InputLabel>
                        <TextField
                            name="name"
                            fullWidth
                            value={productCreateData['name'] || ''}
                            onChange={handleProductCreateData}
                        />
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <InputLabel>Sub Header</InputLabel>
                        <TextField
                            name="subHeader"
                            fullWidth
                            value={productCreateData['subHeader'] || ''}
                            onChange={handleProductCreateData}
                        />
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <InputLabel>Group</InputLabel>
                        <TextField
                            name="groupName"
                            fullWidth
                            value={productCreateData['groupName'] || ''}
                            onChange={handleProductCreateData}
                        />
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <InputLabel shrink>Description</InputLabel>

                        <RichTextEditor
                            size="medium"
                            value={productCreateData['description'] || ''}
                            onEditorChange={(value) => {
                                setProductCreateDataSingle('description', value);
                            }}
                            name="description"
                        />
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <InputLabel>Goals</InputLabel>
                        <Select
                            multiple
                            name="goals"
                            value={productCreateData['goals'] || []}
                            onChange={handleProductCreateData}
                            input={<OutlinedInput fullWidth />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                                </Box>
                            )}
                        >
                        {acquisitionGoalTypes.map(({name, key}) => (
                            <MenuItem
                                key={name}
                                value={name}
                            >
                                {name}
                            </MenuItem>
                        ))}
                        </Select>
                    </FieldContainer>

                    <FieldContainer xs={12}>

                        <InputLabel>Channels</InputLabel>
                        <Select
                            multiple
                            name="channels"
                            value={productCreateData['channels'] || []}
                            onChange={handleProductCreateData}
                            input={<OutlinedInput fullWidth />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                ))}
                                </Box>
                            )}
                        >
                        {acquisitionChannelTypes.map(({name, key}) => (
                            <MenuItem
                                key={name}
                                value={name}
                            >
                                {name}
                            </MenuItem>
                        ))}
                        </Select>
                    </FieldContainer>

                    <FieldContainer xs={12}>

                        <InputLabel>Support Level</InputLabel>
                        <RadioGroup
                            row
                            name="supportLevel"
                            value={productCreateData['supportLevel'] || ''}
                            onChange={handleProductCreateData}
                        >
                            <FormControlLabel value="Support Required" control={<Radio />} label="Support Required" />
                            <FormControlLabel value="Self Service" control={<Radio />} label="Self Service" />
                        </RadioGroup>
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <InputLabel shrink>Contact</InputLabel>

                        <LinkList
                            onChange={newValue  => {
                                let updatedData = {...productCreateData};

                                if( !updatedData['data'] ) updatedData['data'] = {};

                                updatedData['data']['contact'] = newValue;

                                setProductCreateDataSingle('data', updatedData['data']);
                            }}
                            value={productCreateData['data'] && productCreateData['data']['contact'] ? productCreateData['data']['contact'] : null}
                            name="contact"
                        />
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <InputLabel shrink>URLs</InputLabel>

                        <LinkList
                            onChange={newValue  => {
                                let updatedData = {...productCreateData};

                                if( !updatedData['data'] ) updatedData['data'] = {};

                                updatedData['data']['urls'] = newValue;
                                
                                setProductCreateDataSingle('data', updatedData['data']);
                            }}
                            value={productCreateData['data'] && productCreateData['data']['urls'] ? productCreateData['data']['urls'] : null}
                            name="urls"
                        />
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <InputLabel shrink>Support</InputLabel>

                        <LinkList
                            onChange={newValue  => {
                                let updatedData = {...productCreateData};

                                if( !updatedData['data'] ) updatedData['data'] = {};

                                updatedData['data']['support'] = newValue;
                                
                                setProductCreateDataSingle('data', updatedData['data']);
                            }}
                            value={productCreateData['data'] && productCreateData['data']['support'] ? productCreateData['data']['support'] : null}
                            name="support"
                        />
                    </FieldContainer>
                    
                    <FieldContainer xs={12}>
                        <InputLabel shrink>Access</InputLabel>

                        <RichTextEditor
                            size="medium"
                            value={productCreateData['data'] && productCreateData['data']['access'] ? productCreateData['data']['access'] : ''}
                            onEditorChange={(value) => {
                                let updatedData = {...productCreateData};

                                if( !updatedData['data'] ) updatedData['data'] = {};

                                updatedData['data']['access'] = value;
                                
                                setProductCreateDataSingle('data', updatedData['data']);
                            }}
                        />
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <InputLabel shrink>Training</InputLabel>

                        <RichTextEditor
                            size="medium"
                            value={productCreateData['data'] && productCreateData['data']['training'] ? productCreateData['data']['training'] : ''}
                            onEditorChange={(value) => {
                                let updatedData = {...productCreateData};

                                if( !updatedData['data'] ) updatedData['data'] = {};

                                updatedData['data']['training'] = value;
                                
                                setProductCreateDataSingle('data', updatedData['data']);
                            }}
                        />
                    </FieldContainer>
                    
                    <FieldContainer xs={12}>
                        <InputLabel shrink>Resources</InputLabel>

                        <RichTextEditor
                            size="medium"
                            value={productCreateData['data'] && productCreateData['data']['resources'] ? productCreateData['data']['resources'] : ''}
                            onEditorChange={(value) => {
                                let updatedData = {...productCreateData};

                                if( !updatedData['data'] ) updatedData['data'] = {};

                                updatedData['data']['resources'] = value;
                                
                                setProductCreateDataSingle('data', updatedData['data']);
                            }}
                        />
                    </FieldContainer>

                    <FieldContainer xs={12} sx={{textAlign: 'right'}}>
                        <LoadingButton
                            disabled={false}
                            onClick={handleProductCreateSubmit}
                            variant="contained"
                            type="submit"
                            loading={false}
                            loadingPosition="end"
                            endIcon={productCreateData['id'] ? <SaveIcon /> : <AddBoxIcon />}
                        >
                            {productCreateData['id'] ? 'Save Changes' : 'Add New Product'}
                        </LoadingButton>
                    </FieldContainer>

                </Fieldset>
            </ModalDialogue>
            <Hero size="small">
                <HeroText>
                    <Typography variant="h4" sx={{mb: 1}}>Welcome</Typography>
                    <Typography paragraph sx={{mb:0}}>Discover WMG campaign building tools, products and more to reach your audience by using the filters below.</Typography>
                </HeroText>
                <HeroButtonGroup>
                    <HeroButton component={MLink} href="https://wmg.sharepoint.com/sites/CRMKnowledgeBase" target="_blank" color="neutral">Visit the Knowledge Base<InfoIcon /></HeroButton>
                    {userAdminAccess && <HeroButton onClick={() => setProductCreateModalShow(true)} color="primary">Add New Product<AddCircleOutlineIcon /></HeroButton>}
                </HeroButtonGroup>
            </Hero>
            <PageContainer>
                
                <Grid container>
                    <Grid item container spacing={1} sx={{pb: 4}}>
                        <Typography item sx={{fontSize: '0.8rem', flex: '0 0 100%', textTransform: 'uppercase', pl: 1}}>Select Your Main Goal</Typography>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>

                                {filterData.goals.map((item, itemIndex) => (
                                    <Grid xs={2} key={itemIndex} item sx={containerStyle}>
                                        <Tooltip placement="bottom-start" title={item.tooltip}>
                                            <ToggleButtonGroup fullWidth={true} key={itemIndex} value={item.selected} sx={{paddingBottom: '8px'}}>
                                                <ToggleButton value={true} onClick={toggleFilter('goals', itemIndex)} sx={{...buttonStyleTop, minHeight: '76px'}}>{/*<Box sx={{width: '9px', height: '9px', backgroundColor: '#46bee6', mb: 1}} />*/}<Typography sx={{fontSize: '1rem', fontWeight: 'bold'}}>{item.name}</Typography></ToggleButton>
                                            </ToggleButtonGroup>
                                        </Tooltip>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={2}>
                        <Grid container spacing={1}>

                            <Grid item container spacing={1} sx={{p: 2}}>
                                <Typography sx={{fontSize: '0.8rem', flex: '0 0 100%', textTransform: 'uppercase', pl: 1}}>Channel</Typography>

                                {filterData.channels.map((item, itemIndex) => (
                                    <Grid xs={12} key={itemIndex} item sx={containerStyle}>
                                        <ToggleButtonGroup fullWidth={true} key={itemIndex} value={item.selected}>
                                            <ToggleButton value={true} onClick={toggleFilter('channels', itemIndex)} sx={buttonStyle}>{/*<Box sx={{width: '9px', height: '9px', backgroundColor: '#ed6c02', mb: 1}} />*/}<Typography sx={{fontSize: '0.8rem'}}>{item.name}</Typography></ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                ))}
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={10} sx={{pl: 2}}>
                        <Grid container spacing={1}>
                            {
                                !productData && <Grid item xs={12}><Loader /></Grid>
                            }
    {
        groupDataSorted &&
        groupDataSorted
        .map( (group) => {
            var groupInfo = groupData.find(groupDataSingle => groupDataSingle.name === group.name);

            return (
                <Grid key={group.name} item xs={4}>
                    <Card sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                        <CardHeader
                            sx={{...cardheaderStyle, ml: 1, mr: 1}}
                            subheader={groupInfo.subTitle}
                            title={group.name}
                        />

                        { groupInfo &&
                            <CardContent sx={{mt: 0, px: 2, mr: 1, pb: 2, ml: 1, pt: 0, minHeight: '140px'}}>
                                {groupInfo.description}
                            </CardContent>
                        }

                        <CardActions sx={{flexDirection: 'row-reverse', height: '100%', pr: 2, pb: 2}}>
                            <Button onClick={() => { setGroupModalShow(group.name); }} variant="outlined" sx={{ml: 1}}>Expand</Button>
                        </CardActions>
                    </Card>

                    <ModalDialogue backdropClick={true} showFooter={false} open={groupModalShow === group.name} onClose={(e, r) => { history.push('/dashboard'); setGroupModalShow(null); }}>
                        <CardHeader
                            sx={{ml: 1, mr: 1}}
                            title={group.name}
                        />

                        { groupInfo &&
                            <CardContent sx={{mt: 0, px: 2, mr: 1, pb: 2, ml: 1, pt: 0}}>
                                {groupInfo.descriptionLong}
                            </CardContent>
                        }

                        <Box sx={{px: 2, pb: 2}}>

                            <Grid container spacing={1}>
                                {
                                    group.products &&
                                    group.products
                                    .map( (product) => {
                                        return (
                                            <Grid key={product.getId()} item xs={6}>
                                                <Card sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                                                    <CardHeader
                                                        sx={{...cardheaderStyle, ml: 1, mr: 1}}
                                                        title={product.getName()}
                                                        subheader={product.getSubHeader()}
                                                    />
                            
                                                    <CardContent sx={{mt: 0, px: 2, mr: 1, pb: 2, ml: 1, pt: 0, minHeight: '140px'}}>
                                                        {
                                                            product.getDescription() &&
                                                                <Grid container spacing={2}>
                                                                    <Grid xs={12} item>{formatHTML(product.getDescription().length > 160 ? product.getDescription().substring(0, 160).trim()+'…' : product.getDescription())}</Grid>
                                                                </Grid>
                                                        }
                                                    </CardContent>

                                                    <CardActions sx={{flexDirection: 'row-reverse', height: '100%', pr: 2, pb: 2}}>
                                                        <Button onClick={() => { setProductModalShow(product.getId()); }} variant="outlined" sx={{ml: 1}}>See More</Button>
                            
                                                        { userAdminAccess &&
                                                            <Button
                                                                onClick={() => {
                                                                    setProductCreateDataSingle('id', product.getId());
                                                                    setProductCreateDataSingle('name', product.getName());
                                                                    setProductCreateDataSingle('groupName', product.getGroupName());
                                                                    setProductCreateDataSingle('subHeader', product.getSubHeader());
                                                                    setProductCreateDataSingle('description', product.getDescription());
                                                                    setProductCreateDataSingle('productGroup', product.getProductGroup() ? product.getProductGroup().getId() : null);
                                                                    setProductCreateDataSingle('supportLevel', product.getSupportLevel());
                                                                    setProductCreateDataSingle('goals', product.getGoals());
                                                                    setProductCreateDataSingle('channels', product.getChannels());
                                                                    setProductCreateDataSingle('data', product.getData());
                                
                                                                    setProductCreateModalShow(true);
                                                                }}
                                                                variant="outlined">
                                                                    Edit
                                                            </Button>
                                                        }
                                                    </CardActions>
                                                </Card>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Box>
                    </ModalDialogue>

                </Grid>
            )
        })
    }
    {
        productDataSorted &&
        productDataSorted
        .map( (product) => {
            var groupName = product.getGroupName();

            return (
                <Grid key={product.getId()} item xs={4}>
                    <Card sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
                        <CardHeader
                            sx={{...cardheaderStyle, ml: 1, mr: 1}}
                            title={product.getName()}
                            subheader={product.getSubHeader()}
                        />

                        <CardContent sx={{mt: 0, px: 2, mr: 1, pb: 2, ml: 1, pt: 0, minHeight: '140px'}}>
                            {
                                product.getDescription() &&
                                    <Grid container spacing={2}>
                                        {/*<Grid xs={2.5} item><Typography variant="body2" color="text.secondary">About</Typography></Grid>*/}
                                        <Grid xs={12} item>{formatHTML(product.getDescription().length > 160 ? product.getDescription().substring(0, 160).trim()+'…' : product.getDescription())}</Grid>
                                    </Grid>
                            }
                        </CardContent>

                        <CardActions sx={{flexDirection: 'row-reverse', height: '100%', pr: 2, pb: 2}}>
                            <Button onClick={() => { setProductModalShow(product.getId()); }} variant="outlined" sx={{ml: 1}}>See More</Button>

                            { userAdminAccess &&
                                <Button
                                    onClick={() => {
                                        setProductCreateDataSingle('id', product.getId());
                                        setProductCreateDataSingle('name', product.getName());
                                        setProductCreateDataSingle('groupName', product.getGroupName());
                                        setProductCreateDataSingle('subHeader', product.getSubHeader());
                                        setProductCreateDataSingle('description', product.getDescription());
                                        setProductCreateDataSingle('productGroup', product.getProductGroup() ? product.getProductGroup().getId() : null);
                                        setProductCreateDataSingle('supportLevel', product.getSupportLevel());
                                        setProductCreateDataSingle('goals', product.getGoals());
                                        setProductCreateDataSingle('channels', product.getChannels());
                                        setProductCreateDataSingle('data', product.getData());

                                        setProductCreateModalShow(true);
                                    }}
                                    variant="outlined">
                                        Edit
                                </Button>
                            }
                        </CardActions>
                    </Card>


                </Grid>
            )
        })
    }

    {
        productData &&
        productData.getItems().map(product => (
            
            <ModalDialogue backdropClick={true} showFooter={false} key={product.getId()} open={productModalShow === product.getId()} onClose={(e, r) => { history.push('/dashboard'); setProductModalShow(null); }}>
            <Box sx={{px: 2, pt: 2, pb: 1}}>
                <CardHeader
                    title={product.getName()}
                    subheader={product.getSubHeader()}
                    action={
                        <Button
                            startIcon={<ShareIcon />}
                            onClick={e => {
                                navigator.clipboard.writeText(window.location.protocol+'//'+window.location.host+'/dashboard/products/'+product.getId()+'?token='+btoa(userDetails.user.id.toString()+userDetails.user.email));

                                enqueueSnackbar("Product URL copied to clipboard", {
                                    variant: 'success'
                                });
                            }}
                            color="primary"
                            sx={{ml: 1}}
                        >
                            Share
                        </Button>
                    }

                />

                <Box
                    sx={{mt: 2, px: 2, mr: 2, pb: 8, marginLeft: '56px'}}
                >
                    <Grid container spacing={2}>
                    {
                        product.getDescription() &&
                            <>
                                <Grid xs={3} item><Typography variant="body2" color="text.secondary">About</Typography></Grid>
                                <Grid xs={9} item>{formatHTML(product.getDescription())}</Grid>
                            </>
                    }
                    {
                        ( product.getData() && product.getData().urls ) &&
                            <>
                                <Grid xs={3} item><Typography variant="body2" color="text.secondary">URLs</Typography></Grid>
                                <Grid xs={9} item>
                                    {
                                        product.getData().urls.map ((link, linkIndex) => (
                                            <Typography key={linkIndex} variant="body2" color="text.primary"><MLink target="_blank" href={link.link}>{link.label || link.link}</MLink></Typography>
                                        ))
                                    }
                                </Grid>
                            </>
                    }
                    {
                        ( product.getData() && product.getData().contact ) &&
                            <>
                                <Grid xs={3} item><Typography variant="body2" color="text.secondary">Contact</Typography></Grid>
                                <Grid xs={9} item>
                                    {
                                        product.getData().contact.map(link => (
                                            <Typography key={link.link} variant="body2" color="text.primary"><MLink target="_blank" href={link.link.includes('@') ? 'mailto:'+link.link : link.link}>{link.label || link.link}</MLink></Typography>
                                        ))
                                    }
                                </Grid>
                            </>
                    }
                    {
                        ( product.getData() && product.getData().support ) &&
                            <>
                                <Grid xs={3} item><Typography variant="body2" color="text.secondary">Support</Typography></Grid>
                                <Grid xs={9} item>
                                    {
                                        product.getData().support.map(link => (
                                            <Typography key={link.link} variant="body2" color="text.primary"><MLink target="_blank" href={link.link.includes('@') ? 'mailto:'+link.link : link.link}>{link.label || link.link}</MLink></Typography>
                                        ))
                                    }
                                </Grid>
                            </>
                    }
                    {
                        ( product.getData() && product.getData().access ) &&
                            <>
                                <Grid xs={3} item><Typography variant="body2" color="text.secondary">Access</Typography></Grid>
                                <Grid xs={9} item>{formatHTML(product.getData().access)}</Grid>
                            </>
                    }
                    {
                        ( product.getData() && product.getData().training ) &&
                            <>
                                <Grid xs={3} item><Typography variant="body2" color="text.secondary">Training</Typography></Grid>
                                <Grid xs={9} item>{formatHTML(product.getData().training)}</Grid>
                            </>
                    }
                    {
                        ( product.getData() && product.getData().resources ) &&
                            <>
                                <Grid xs={3} item><Typography variant="body2" color="text.secondary">Resources</Typography></Grid>
                                <Grid xs={9} item>{formatHTML(product.getData().resources)}</Grid>
                            </>
                    }
                    </Grid>
                </Box>
            </Box>
        </ModalDialogue>
        ))
    }

    {
        ( productDataSorted && ( productDataSorted.length === 0 ) && productDataSorted && ( productDataSorted.length === 0 ) ) &&
            <Grid item xs={12}><ContentBox><Alert severity="info">Your search returned no matches</Alert></ContentBox></Grid>
    }


                        </Grid>
                    </Grid>
                </Grid>
            </PageContainer>
        </>
    );

};

export default HomeDashboard;