import React, { useState, useEffect, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { UserRepository, UserAPI } from '@warnerconnect/library';

import { TablePagination, Button, Typography, CircularProgress, Link as MLink, Grid, Paper, MenuList, MenuItem, ListItemText, ListItemIcon, Checkbox, Box, Divider, InputLabel, TextField, Alert } from '@mui/material';

import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';

import { loginUser, useAuthDispatch, useAuthState } from '../../../Context';

import { useHistory } from "react-router-dom";

import {
    ArrowForward as ArrowForwardIcon,
    Login as LoginIcon,
    Key as KeyIcon
  } from '@mui/icons-material';

import Loader from '../../Elements/Loader';
import CampaignList from '../../Elements/CampaignList';
import useQuery from '../../Utilities/useQuery';
import useTitle from '../../Utilities/useTitle';
import ContentBox from '../../Elements/ContentBox';
import PageContainer from './../../Elements/PageContainer';
import HeroText from '../../Elements/HeroText';
import Hero from '../../Elements/Hero';
import HeroButtonGroup from '../../Elements/HeroButtonGroup';
import HeroButton from '../../Elements/HeroButton';
import TableBarChart from '../../Elements/TableBarChart';
import BarChart from '../../Elements/Chart/BarChart';
import LineChart from '../../Elements/Chart/LineChart';

import {
    OktaAuth,
    OktaAuthOptions,
    TokenManagerInterface,
    AccessToken,
    IDToken,
    UserClaims,
    TokenParams
} from '@okta/okta-auth-js';

import CampaignsHeroImage from '../../../assets/media/hero/campaigns.jpg';
import Fieldset from '../../Elements/Forms/Fieldset';
import FieldContainer from '../../Elements/Forms/FieldContainer';

import { formReducer } from '../../../Context/formReducer';
import { LoadingButton } from '@mui/lab';
import ModalDialogue from '../../Elements/ModalDialogue';

export default function AccountIndex () {
    useTitle('Account');

    const userDetails = useAuthState();

    const [accountVerificationLoading, setAccountVerificationLoading] = useState(false);
    const [accountVerificationErrors, setAccountVerificationErrors] = useState();
    const [accountVerificationCompleted, setAccountVerificationCompleted] = useState(false);

    const [userDataModal, setUserDataModal] = useState(false);
    const [userDataSubmitting, setUserDataSubmitting] = useState(false);
    const [userData, setUserData] = useReducer(formReducer, {});
    const [userDataCompleted, setUserDataCompleted] = useState(false);
    const [userDataErrors, setUserDataErrors] = useState();
    
    const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
    const [forgotPasswordSubmitting, setForgotPasswordSubmitting] = useState(false);
    const [forgotPasswordData, setForgotPasswordData] = useReducer(formReducer, {});
    const [forgotPasswordCompleted, setForgotPasswordCompleted] = useState(false);
    const [forgotPasswordErrors, setForgotPasswordErrors] = useState();

    const [oktaLogInSubmitting, setOktaLogInSubmitting] = useState(false);
    const [oktaLogInErrors, setOktaLogInErrors] = useState();

    const [logInSubmitting, setLogInSubmitting] = useState(false);
    const [logInData, setLogInData] = useReducer(formReducer, {});
    const [logInErrors, setLogInErrors] = useState();

    const userRepository = new UserRepository();
    const userAPI = new UserAPI();
    userAPI.setBearerToken(userDetails.accessToken);
    userRepository.setBearerToken(userDetails.accessToken);
  
    const history = useHistory();
    const [userTokenData, setUserToken] = useState();
    const [verificationCode, setVerificationCode] = useState();
	const dispatch = useAuthDispatch();

    const setLogInDataSingle = (name, value) => {
        setLogInData({ name: name, value: value});
    }

    const handleLogInChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setLogInDataSingle(
            event.target.name,
            isCheckbox ? event.target.checked : event.target.value
        );
    }

    const toggleForgotPasswordModal = event => {
        setForgotPasswordData({ type: 'reset' });
        setForgotPasswordModal(!forgotPasswordModal);
    }

    const setForgotPasswordDataSingle = (name, value) => {
        setForgotPasswordData({ name: name, value: value});
    }

    const handleForgotPasswordChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setForgotPasswordDataSingle(
            event.target.name,
            isCheckbox ? event.target.checked : event.target.value
        );
    }

    const toggleUserDataModal = event => {
        setUserData({ type: 'reset' });
        setUserDataModal(!userDataModal);
    }

    const setUserDataSingle = (name, value) => {
        setUserData({ name: name, value: value});
      };
    
    const handleUserDataChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setUserDataSingle(
            event.target.name,
            isCheckbox ? event.target.checked : event.target.value
        );
    }

    useEffect(() => {
		if( userTokenData && userTokenData.getUser() )
		{
			if( loginUser(dispatch, userTokenData) )
			{
				history.push(window.location.pathname);
			}
		}
    }, [userTokenData]);

    useEffect(() => {
		if( verificationCode )
		{
            setAccountVerificationLoading(true);

            userAPI.verifyAccount(verificationCode)
                .then(response => {
                    setAccountVerificationCompleted(true);
                })
                .catch(error => {
                    setAccountVerificationErrors([error.message]);
                })
                .finally(response => {
                    setAccountVerificationLoading(false);
    				history.push(window.location.pathname);
                });

		}
    }, [verificationCode]);

    useEffect(() => {
		const urlParams = new Proxy(new URLSearchParams(window.location.search), {
			get: (searchParams, prop) => searchParams.get(prop),
		});

		if( urlParams.verificationCode )
		{
			setVerificationCode(urlParams.verificationCode);
		}
    }, []);

    let config = {
        issuer: 'https://wmg.okta.com',
        clientId: '0oamtsjy7dtsVkfzt2p7',
        redirectUri: location.protocol + '//' + location.host,
    };
    
    let authClient = new OktaAuth(config);

    let tokenParams = {
        scopes: ['openid', 'email', 'groups', 'profile', 'offline_access', 'okta.roles.read'],
    };
    
    const urlParams = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });

    // Handle callback
    if (!urlParams.verificationCode && authClient.token.isLoginRedirect()) {
        setOktaLogInSubmitting(true);
        setOktaLogInErrors(null);

        authClient.token.parseFromUrl().then( ({tokens}) => {
            userRepository
                .oktaLogin(tokens.accessToken.accessToken, tokens.refreshToken.refreshToken, tokens.accessToken.expiresAt)
                .then(response => {
                    setUserToken(response);
                })
                .catch(error => {
                    setOktaLogInErrors([error.message]);
                })
                .finally( () => {
                    setOktaLogInSubmitting(false);
                });
        });
    }


    // normal app startup
    authClient.start(); // will update auth state and call event listeners

    return (
        <>

            <ModalDialogue open={userDataModal} onClose={toggleUserDataModal}>
                <Box sx={{px: 2, pt: 2}}>

                    <Typography variant="h4" gutterBottom>Register</Typography>
                    <Typography paragraph gutterBottom>Enter your details below to register for an account.</Typography>
                    {
                        userDataCompleted &&
                            <Alert severity="success" sx={{mb: 2}}>Thanks for registering. You should receive an email with instructions on how to activate your account in the next 15 minutes. If you have any issues with this, please reach out to us via <MLink target="_blank" href="https://support.wmgconnect.com">our support page</MLink>.</Alert>
                    }
                </Box>
                {
                    !userDataCompleted &&
                        <form autoComplete="off">
                            <Fieldset>
                                <FieldContainer xs={12}>
                                        <InputLabel shrink>Name</InputLabel>
                                        <TextField
                                            name="name"
                                            fullWidth
                                            value={userData['name'] || ''}
                                            onChange={handleUserDataChange}
                                        />
                                    </FieldContainer>

                                    <FieldContainer xs={12}>
                                        <InputLabel shrink>Email</InputLabel>
                                        <TextField
                                            name="email"
                                            type="email"
                                            fullWidth
                                            value={userData['email'] || ''}
                                            onChange={handleUserDataChange}
                                        />
                                    </FieldContainer>

                                    <FieldContainer xs={12}>
                                        <InputLabel shrink>Password</InputLabel>
                                        <TextField
                                            name="newPassword"
                                            type="password"
                                            fullWidth
                                            value={userData['newPassword'] || ''}
                                            onChange={handleUserDataChange}
                                        />
                                    </FieldContainer>

                                    <FieldContainer xs={12}>
                                        <InputLabel shrink>Confirm Password</InputLabel>
                                        <TextField
                                            name="newPasswordConfirm"
                                            type="password"
                                            fullWidth
                                            value={userData['newPasswordConfirm'] || ''}
                                            onChange={handleUserDataChange}
                                        />
                                    </FieldContainer>

                                    {
                                        userDataErrors &&
                                            <FieldContainer xs={12}>
                                                {
                                                    userDataErrors
                                                        .map( error => (
                                                            <Alert severity="error">{error}</Alert>
                                                        ))
                                                }
                                            </FieldContainer>
                                    }

                                    <FieldContainer xs={12} sx={{textAlign: 'right'}}>

                                        <LoadingButton
                                            type="button"
                                            disabled={!(userData['name'] && userData['email'] && userData['newPassword'] && ( userData['newPassword'] === userData['newPasswordConfirm'] ))}
                                            loading={userDataSubmitting}
                                            onClick={() => {
                                                setUserDataSubmitting(true);
                                                setUserDataErrors(null);

                                                userRepository
                                                    .createUser({
                                                        name: userData['name'],
                                                        email: userData['email'],
                                                        plainPassword: userData['newPassword']
                                                    })
                                                    .then(response => {
                                                        setUserDataCompleted(true);
                                                    })
                                                    .catch(error => {
                                                        setUserDataErrors([error.message]);
                                                    })
                                                    .finally( () => {
                                                        setUserDataSubmitting(false);
                                                    });
                                            }}
                                            loadingPosition="end"
                                            variant="contained"
                                            endIcon={<ArrowForwardIcon />}
                                        >
                                            Register
                                        </LoadingButton>

                                    </FieldContainer>
                                </Fieldset>
                            </form>
                }
                
            </ModalDialogue>

            <ModalDialogue open={forgotPasswordModal} onClose={toggleForgotPasswordModal}>
                <Box sx={{px: 2, pt: 2}}>
                    <Typography variant="h4" gutterBottom>Forgot Password</Typography>
                    <Typography paragraph gutterBottom>Enter your email below and we'll send you an email to help you get back into your account.</Typography>
                    {
                        forgotPasswordCompleted &&
                            <Alert severity="success" sx={{mb: 2}}>You should receive an email with a new password in the next few minutes. If you don't receive an email please get in touch with us through <MLink target="_blank" href="https://support.wmgconnect.com">our support page</MLink>.</Alert>

                    }
                </Box>
                {
                    !forgotPasswordCompleted &&

                        <form autoComplete="off">
                            <Fieldset>
                                <FieldContainer xs={12}>
                                    <InputLabel shrink>Email</InputLabel>
                                    <TextField
                                        name="email"
                                        type="email"
                                        fullWidth
                                        value={forgotPasswordData['email'] || ''}
                                        onChange={handleForgotPasswordChange}
                                    />
                                </FieldContainer>

                                {
                                    forgotPasswordErrors &&
                                        <FieldContainer xs={12}>
                                            {
                                                forgotPasswordErrors
                                                    .map( error => (
                                                        <Alert severity="error">{error}</Alert>
                                                    ))
                                            }
                                        </FieldContainer>
                                }

                                <FieldContainer xs={12} sx={{textAlign: 'right'}}>
                                    <LoadingButton
                                        sx={{mb: 2}}
                                        type="submit"
                                        disabled={!forgotPasswordData['email']}
                                        loading={forgotPasswordSubmitting}
                                        loadingPosition="end"
                                        variant="contained"
                                        endIcon={<KeyIcon />}
                                        onClick={() => {
                                            setForgotPasswordSubmitting(true);
                                            setForgotPasswordErrors(null);

                                            userAPI.resetPassword(forgotPasswordData['email'])
                                                .then(response => {
                                                    setForgotPasswordCompleted(true);
                                                })
                                                .catch(error => {
                                                    setForgotPasswordErrors([error.message]);
                                                })
                                                .finally( () => {
                                                    setForgotPasswordSubmitting(false);
                                                });
                                        }}
                                    >
                                        Submit
                                    </LoadingButton>
                                </FieldContainer>
                            </Fieldset>
                        </form>
                }
            </ModalDialogue>

            <Hero image={CampaignsHeroImage}>
                <HeroText>
                    <Typography variant="h4" sx={{mb: 1}}>Welcome</Typography>
                    <Typography paragraph>{process.env.REACT_APP_WEBSITE_NAME} is your destination for fan acquisition, insight and engagement.</Typography>
                </HeroText>
                <HeroButtonGroup>
                    <HeroButton color="secondary" onClick={toggleForgotPasswordModal}>Forgot Password<KeyIcon /></HeroButton>
                    <HeroButton color="primary" onClick={toggleUserDataModal}>Register<LoginIcon /></HeroButton>
                </HeroButtonGroup>
            </Hero>

            <PageContainer>
                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={6}>
                        {
                            accountVerificationLoading &&
                                <Alert sx={{mb: 2}} severity="info" icon={<CircularProgress size={22} />}>Checking your verification code.</Alert>
                        }
                        {
                            accountVerificationCompleted &&
                                <Alert sx={{mb: 2}} severity="success">Your validation code has been accepted & you can now login below.</Alert>
                        }
                        {
                            accountVerificationErrors &&
                                accountVerificationErrors
                                    .map( error => (
                                        <Alert sx={{mb: 2}} severity="error">{error}</Alert>
                                    ))
                        }

                        <Typography variant="h4" gutterBottom>Log In</Typography>

                        <ContentBox>

                            <Fieldset>

                                <FieldContainer xs={12}>
                                    <InputLabel shrink>Email</InputLabel>
                                    <TextField
                                        name="email"
                                        type="email"
                                        fullWidth
                                        value={logInData['email'] || ''}
                                        onChange={handleLogInChange}
                                    />
                                </FieldContainer>

                                <FieldContainer xs={12}>
                                    <InputLabel shrink>Password</InputLabel>
                                    <TextField
                                        name="password"
                                        type="password"
                                        fullWidth
                                        value={logInData['password'] || ''}
                                        onChange={handleLogInChange}
                                    />
                                </FieldContainer>

                                {
                                    logInErrors &&
                                        <FieldContainer xs={12}>
                                            {
                                                logInErrors
                                                    .map( error => (
                                                        <Alert severity="error">{error}</Alert>
                                                    ))
                                            }
                                        </FieldContainer>
                                }
                                {
                                    oktaLogInErrors &&
                                        <FieldContainer xs={12}>
                                            {
                                                oktaLogInErrors
                                                    .map( error => (
                                                        <Alert severity="error">{error}</Alert>
                                                    ))
                                            }
                                        </FieldContainer>
                                }
                                <FieldContainer xs={6}>
                                    {<LoadingButton
                                        type="button"
                                        loading={oktaLogInSubmitting}
                                        onClick={() => {
                                            authClient.token.getWithRedirect(tokenParams);
                                        }}
                                        loadingPosition="end"
                                        variant="contained"
                                        endIcon={<KeyIcon />}
                                    >
                                        Log In with OKTA
                                    </LoadingButton>}

                                </FieldContainer>

                                <FieldContainer xs={6} sx={{textAlign: 'right'}}>

                                    <LoadingButton
                                        type="submit"
                                        disabled={!(logInData['email'] && logInData['password'])}
                                        loading={logInSubmitting}
                                        onClick={() => {
                                            setLogInSubmitting(true);
                                            setLogInErrors(null);

                                            userRepository
                                                .login(logInData['email'], logInData['password'])
                                                .then(response => {
                                                    setUserToken(response);
                                                })
                                                .catch(error => {
                                                    setLogInErrors([error.message]);
                                                })
                                                .finally( () => {
                                                    setLogInSubmitting(false);
                                                });
                                        }}
                                        loadingPosition="end"
                                        variant="contained"
                                        endIcon={<ArrowForwardIcon />}
                                    >
                                        Log In
                                    </LoadingButton>


                                </FieldContainer>
                            </Fieldset>

                        </ContentBox>

                    </Grid>
                    
                </Grid>

            </PageContainer>
        </>
    );

};
