import Image from 'mui-image'
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import dateFormat, { masks } from "dateformat";
import { Link } from "react-router-dom";
import MLink from '@mui/material/Link';
import Flag from 'react-world-flags'

import NoImage from './../../assets/img/no-image.png';
import ContextMenu from './ContextMenu.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpotify, faApple, faDeezer, faYoutube, faAmazon, faVk, faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faKeyboard } from '@fortawesome/free-regular-svg-icons'

export default function EntrantListSingle(props) {
    let source = props.data.getEntrant().getSource();
    let icon = null;
    let fields = props.fields || null;

    if( source == 'google' )
    {
        icon = <FontAwesomeIcon icon={faYoutube} />;
        source = 'youTube';
    }
    else if( source == 'deezer' )
    {
        icon = <FontAwesomeIcon icon={faDeezer} />;
    }
    else if( source == 'vkontakte' )
    {
        icon = <FontAwesomeIcon icon={faVk} />;
    }
    else if( source == 'spotify' )
    {
        icon = <FontAwesomeIcon icon={faSpotify} />;
    }
    else if( source == 'amazon' )
    {
        icon = <FontAwesomeIcon icon={faAmazon} />;
    }
    else if( source == 'appleMusic' )
    {
        icon = <FontAwesomeIcon icon={faApple} />;
    }
    else if( source == 'facebook' )
    {
        icon = <FontAwesomeIcon icon={faFacebook} />;
    }
    else
    {
        icon = <FontAwesomeIcon icon={faKeyboard} />;
    }

    let label = props.data.getId();

    if( props.labelField === 'email' )
    {
        label = props.data.getEntrant().getEmail();
    }

    return (
        <TableRow key={props.data.getId()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell sx={{width: '10%', paddingRight: 0}}><Flag height="18" width="26" style={{display: 'flex'}} code={props.data.getEntrant().getAddressCountry()} /></TableCell>
            {
                ( fields && fields.includes('addressCity') ) &&
                    <TableCell sx={{width: '10%', paddingRight: 0}}>
                        <Typography variant="subtitle2">{props.data.getEntrant().getAddressCity()}</Typography>
                    </TableCell>
            }
            <TableCell sx={{width: '10%', paddingRight: 0}}>{icon}</TableCell>
            <TableCell sx={{width: '40%', paddingRight: 0}}>
                <Typography variant="subtitle2">{label}</Typography>
            </TableCell>
            <TableCell sx={{width: '30%', paddingRight: 0}}>
                <Typography variant="subtitle2">{props.data.getDateTimeCreated() ? dateFormat(props.data.getDateTimeCreated(), "mmm dS yyyy HH:MMTT") : ''}</Typography>
            </TableCell>
            <TableCell sx={{width: '10%', paddingRight: 0}}>
                {
                    props.showOptions &&
                        <ContextMenu
                            options={[
                                {label: 'Sync with DSP'},
                                {label: 'Sync with ExactTarget'},
                                {label: 'Delete'}
                            ]}
                        />
                }
            </TableCell>
        </TableRow>
    );
}