import React, { useState, useReducer } from 'react';
import jwt_decode from "jwt-decode";

let user;
let accessToken;
let refreshToken;

if( localStorage.getItem('currentUser') )
{
	try
	{
		var currentUser = JSON.parse(localStorage.getItem('currentUser'));

		accessToken = currentUser.accessToken;

		user = currentUser.user;
	}catch(e){ localStorage.removeItem('currentUser') }

}

if( localStorage.getItem('currentTeam') )
{
	var team = JSON.parse(localStorage.getItem('currentTeam'));
}

export const initialState = {
	user: user || '',
	team: team || null,
	accessToken: accessToken || '',
	refreshToken: refreshToken || '',
	loading: false,
	errorMessage: null,
};

export const AuthReducer = (initialState, action) => {
	switch (action.type) {
		case 'REQUEST_LOGIN':
			return {
				...initialState,
				loading: true,
			};
		case 'LOGIN_SUCCESS':
			return {
				...initialState,
				user: action.payload.user,
				accessToken: action.payload.accessToken,
				loading: false,
			};
		case 'LOGOUT':
			return {
				...initialState,
				user: '',
				accessToken: '',
				refreshToken: '',
			};

		case 'LOGIN_ERROR':
			return {
				...initialState,
				loading: false,
				errorMessage: action.error,
			};

		case 'CHANGE_TEAM':
			return {
				...initialState,
				team: action.team,
			};
			
		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};