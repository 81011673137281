import { useState } from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import MLink from '@mui/material/Link';

import ModalDialogue from './ModalDialogue';

import ContextMenu from './ContextMenu.js';

import {
    RemoveCircle as RemoveCircleIcon
} from '@mui/icons-material';

import { Button, Box } from '@mui/material';

export default function TeamUserListSingle(props) {
    const [deleteModal, setDeleteModal] = useState(false);

    const toggleDeleteModal = event => {
        setDeleteModal(!deleteModal);
    }

    var type = 'User'

    if( props.data.getRoles() && ( props.data.getRoles().includes('ROLE_ADMIN') ) )
    {
        type = 'Admin';
    }

    return (
        <TableRow key={props.data.getId()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell sx={{width: '30%', paddingRight: 0}} component="th" scope="row">
                <Typography variant="body1">{props.data.getName()}</Typography>
            </TableCell>
            <TableCell sx={{width: '30%', paddingRight: 0}}>
                <Typography variant="subtitle2">{props.data.getEmail()}</Typography>
            </TableCell>
            <TableCell sx={{width: '30%', paddingRight: 0}}>
                <Typography variant="subtitle2">{type}</Typography>
            </TableCell>
            <TableCell sx={{width: '10%', paddingRight: 0}}>
                <ModalDialogue showFooter={false} open={deleteModal} onClose={toggleDeleteModal}>
                    <Box sx={{px: 2, pt: 2, pb: 1}}>

                        <Typography mb={2} variant="h4">Remove From Team</Typography>
                        <Typography variant="body1">Are you sure you want to remove {props.data.getName()} ({props.data.getEmail()}) from this team.</Typography>

                        <Box sx={{textAlign: 'right'}}>
                            <Button
                                onClick={toggleDeleteModal}
                                sx={{mt: 2, ml: 2}}
                            >
                                Cancel
                            </Button>

                            <Button
                                onClick={() => {
                                    if( props.onDelete )
                                    {
                                        props.onDelete(props.data.getId()).then(deleteResponse => {
                                            toggleDeleteModal();
                                        });
                                    }
                                }}
                                sx={{mt: 2, ml: 2}
                            }>
                                Delete
                            </Button>
                        </Box>
   
                    </Box>
                </ModalDialogue>
                
                <ContextMenu
                    options={[
                        {icon: <RemoveCircleIcon />, label: 'Remove from Team', onClick: toggleDeleteModal},
                    ]}
                />
            </TableCell>
        </TableRow>
    );
}
