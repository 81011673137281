import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

export default function Hero(props) {

  let _props = {sx: {backgroundColor: '#ffff02', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', marginBottom: '4rem', paddingTop: '1px' }};

  if( props.image )
  {
    _props.sx.backgroundImage = `url(${props.image})`
  }
  
  return (
    <Box {..._props}>
      <Container sx={{minHeight: props.size === 'small' ? '204px' : '300px', position: 'relative'}} maxWidth="xl">
        {props.children}
      </Container>
    </Box>
  );
}