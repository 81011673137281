import { useEffect } from 'react';

export default function useTitle(title) {
  useEffect(() => {
    const prevTitle = document.title;

    document.title = title+( process.env.REACT_APP_WEBSITE_NAME ? ' - '+process.env.REACT_APP_WEBSITE_NAME : '' );

    return () => {
      document.title = prevTitle;
    }
  })
}