import * as React from 'react';
import ContentBox from './ContentBox';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import EntrantListSingle from './EntrantListSingle';

export default function EntrantList(props) {
    let fields = props.fields || null;
    return (
    <TableContainer>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell sx={{width: '10%', paddingRight: 0}}><Typography variant="body1">Country</Typography></TableCell>
                    {
                        ( fields && fields.includes('addressCity') ) &&
                            <TableCell sx={{width: '10%', paddingRight: 0}}><Typography variant="body1">City</Typography></TableCell>
                    }
                    <TableCell sx={{width: '10%', paddingRight: 0}}><Typography variant="body1">Source</Typography></TableCell>
                    <TableCell sx={{width: '40%', paddingRight: 0}}><Typography variant="body1">Name</Typography></TableCell>
                    <TableCell sx={{width: '30%', paddingRight: 0}}><Typography variant="body1">Entered</Typography></TableCell>
                    <TableCell sx={{width: '10%', paddingRight: 0}}><Typography variant="body1"></Typography></TableCell>
                </TableRow>
            </TableHead>
        </Table>
        <ContentBox>
            <Table>
                <TableBody>
                    {props.data.map((row) => (
                        <EntrantListSingle key={row.getId()} data={row} fields={fields} labelField={props.labelField || null} showOptions={props.hasOwnProperty('showOptions') ? props.showOptions : true} />
                    ))}
                </TableBody>
            </Table>
        </ContentBox>
    </TableContainer>
  );
}