import * as React from 'react';

import * as dayjs from 'dayjs'

import { Line, Bar, Utils } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

import Colors from './Colors';
import Grid from '@mui/material/Grid';

export default function BarChart(props) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  let labels = props.labels;
  let dataSets = [];
  let data = props.data;
  var dataListMax = 50;

  data.forEach( (dataList, dataIndex) => {
    var currentDataListMax = Math.max( ...dataList );
    if( currentDataListMax > dataListMax )
    {
        dataListMax = null;
    }

    dataSets.push({
        label: 'Entries',
        data: dataList,
        backgroundColor: Colors[dataIndex].backgroundColor,
        borderColor: Colors[dataIndex].borderColor,
        borderWidth: 1,
        borderRadius: 1,
        borderSkipped: false,
    });
  });

  const options = {
      responsive: true,
      plugins: {
          legend: {
              display: dataSets.length > 1 ? true : false,
              position: 'top',
              labels: {
                  usePointStyle: true,
                  boxWidth: 6
              }
          },
      },
      interaction: {
          intersect: false,
          mode: 'index'
      },
      scales: {
          y: {
              max: dataListMax
          }
      }
  };

  const chartConfig = {
      labels,
      datasets: dataSets,
  };

  return (
      <Bar options={options} data={chartConfig} />
  )
}