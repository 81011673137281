import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ContentBox from './ContentBox';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>{children}</>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabGroup(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{
      mt: props.xs && props.xs.mt ? props.xs.mt : 0,
      p: 0
    }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs variant="fullWidth" value={value} onChange={handleChange}>
        { props.tabList &&
          props.tabList.map((tabSingle, tabSingleIndex) => (
            <Tab key={tabSingleIndex} label={tabSingle.label} {...a11yProps(tabSingleIndex)} />
          ))
        }
        </Tabs>
      </Box>

      { props.tabList &&
        props.tabList.map((tabSingle, tabSingleIndex) => (
          <TabPanel key={tabSingleIndex} value={value} index={tabSingleIndex}>
          {tabSingle.content}
          </TabPanel>
        ))
      }
    </Box>
  );
}
