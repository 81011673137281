import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AcquisitionAPI } from '@warnerconnect/library';

import { TablePagination, Button, Typography, CircularProgress, Link as MLink, Grid, Paper, MenuList, MenuItem, ListItemText, ListItemIcon, Checkbox, Box, ToggleButton, ToggleButtonGroup, Card, CardHeader, CardMedia, CardContent, CardActions, IconButton } from '@mui/material';

import { AddCircleOutline as AddCircleOutlineIcon, Info as InfoIcon, InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material';

import { useAuthDispatch, logout, useAuthState, changeUserTeam } from '../../../Context';

import Image from 'mui-image'

import linkfireLogo from '../../../assets/media/home/icon.linkfire.png';
import fptLogo from '../../../assets/media/home/logo.firepit.png';
import wmcLogo from '../../../assets/media/home/icon.wmc.svg';
import salesforceLogo from '../../../assets/media/home/icon.salesforce.png';
import brandonLogo from '../../../assets/media/home/icon.brandon.png';
import communityLogo from '../../../assets/media/home/icon.community.png';

import linkfireProductPreRelease from '../../../assets/media/home/product.linkfire.release.jpg';

import wmcProductFollow from '../../../assets/media/home/product.wmc.follow.jpg';
import wmcProductSave from '../../../assets/media/home/product.wmc.save.jpg';
import wmcProductPreSave from '../../../assets/media/home/product.wmc.presave.jpg';
import wmcProductUnlock from '../../../assets/media/home/product.wmc.unlock.jpg';
import wmcProductForm from '../../../assets/media/home/product.wmc.form.jpg';
import wmcProductRedeemableDownload from '../../../assets/media/home/product.wmc.redeemablecode.jpg';

import Loader from '../../Elements/Loader';
import CampaignList from '../../Elements/CampaignList';
import useQuery from '../../Utilities/useQuery';
import useTitle from '../../Utilities/useTitle';
import ContentBox from '../../Elements/ContentBox';
import PageContainer from './../../Elements/PageContainer';
import HeroText from '../../Elements/HeroText';
import Hero from '../../Elements/Hero';
import ModalDialogue from '../../Elements/ModalDialogue';
import HeroButtonGroup from '../../Elements/HeroButtonGroup';
import HeroButton from '../../Elements/HeroButton';
import TableBarChart from '../../Elements/TableBarChart';
import BarChart from '../../Elements/Chart/BarChart';
import LineChart from '../../Elements/Chart/LineChart';

import CampaignsHeroImage from '../../../assets/media/hero/campaigns.jpg';

const acquisitionSupportTypes = [
    {name: 'Self Service', key: 'self', selected: false},
    {name: 'Support Required', key: 'support', selected: false}
];

const acquisitionGoalTypes = [
    {name: 'Acquisition', key: 'acquisition', selected: false, color: 'rgba(255, 84, 33, 0.5)'},
    {name: 'Streaming', key: 'streaming', selected: false, color: 'rgba(245, 185, 150, 0.5)'},
    {name: 'Ecommerce', key: 'ecommerce', selected: false, color: 'rgba(210, 210, 255, 0.5)'},
    {name: 'Engagement', key: 'engagement', selected: false, color: 'rgba(129, 199, 185, 0.5)'},
    {name: 'Analysis', key: 'analysis', selected: false, color: 'rgba(180, 255, 71, 0.5)'},
    {name: 'SEO & UX', key: 'seo-ux', selected: false, color: 'rgba(109, 234, 129, 0.5)'}
];

const acquisitionChannelTypes = [
    {name: 'Web', key: 'web', selected: false},
    {name: 'Email', key: 'email', selected: false},
    {name: 'Social Media', key: 'social-media', selected: false},
    {name: 'In-venue', key: 'in-venue', selected: false},
    {name: 'Internal', key: 'internal', selected: false},
    {name: 'Paid Media', key: 'paid-media', selected: false},
    {name: 'SMS', key: 'sms', selected: false},
    {name: 'Paid Campaigns', key: 'paid-campaigns', selected: false}
];

const productFamilies = [
    {
        name: 'Firepit Tech',
        key: 'fpt',
        image: fptLogo
    },
    {
        name: process.env.REACT_APP_WEBSITE_NAME,
        key: 'wmc',
        image: wmcLogo
    },
    {
        name: 'Linkfire',
        key: 'linkfire',
        image: linkfireLogo
    },
    {
        name: 'Salesforce',
        key: 'salesforce',
        image: salesforceLogo
    },
    {
        name: 'Brandon Gentry',
        key: 'brandon',
        image: brandonLogo
    },
    {
        name: 'Community',
        key: 'community',
        image: communityLogo
    }
];

const acquisitionTypes = [
    // Support: SEO / UX
    {
        name: 'Brandon',
        support: ['support'],
        goal: ['seo-ux', 'acquisition'],
        channel: ['internal', 'paid-media', 'in-venue', 'social-media', 'email', 'web'],
        productFamily: 'brandon'
    },
    {
        name: 'Firepit',
        support: ['support'],
        goal: ['seo-ux'],
        channel: ['web'],
        productFamily: 'fpt'
    },

    // Support: Analysis
    {
        name: 'Tag / Pixel Management & Implementation',
        support: ['support'],
        goal: ['analysis', 'ecommerce'],
        channel: ['web', 'paid-media', 'email', 'social-media']
    },
    {
        name: 'Adobe Analytics',
        support: ['support'],
        goal: ['analysis'],
        channel: ['paid-media', 'web', 'social-media']
    },
    {
        name: 'IG / YT Shopping Performance',
        support: ['support'],
        goal: ['analysis', 'ecommerce'],
        channel: []
    },
    {
        name: 'Purchaser data access request',
        support: ['support'],
        goal: ['analysis', 'ecommerce'],
        channel: []
    },
    {
        name: 'Tracking',
        support: ['support'],
        goal: ['analysis'],
        channel: ['email'],
        productFamily: 'salesforce',
        type: 'CRM Data Analysis',
        requirements: 'OKTA Log-in Details',
        benefits: 'Analysis, CRM'
    },
    {
        name: 'Messenger Performance',
        support: ['support'],
        goal: ['analysis'],
        channel: ['social-media']
    },
    {
        name: 'Bot Perfromance',
        support: ['support'],
        goal: ['analysis'],
        channel: ['social-media']
    },
    {
        name: 'Global Audience Team',
        support: ['support'],
        goal: ['analysis'],
        channel: ['social-media']
    },

    // Support: Engagement
    {
        name: 'Artist Voice App',
        support: ['support'],
        goal: ['engagement'],
        channel: ['web']
    },
    {
        name: 'Re-engagement campaign',
        support: ['support'],
        goal: ['engagement'],
        channel: ['email']
    },
    {
        name: 'Post-purchase nurturing campaign',
        support: ['support'],
        goal: ['engagement'],
        channel: ['email']
    },
    {
        name: 'Triggered email automations: Anniversary',
        support: ['support'],
        goal: ['engagement'],
        channel: ['email']
    },
    {
        name: 'Preference centre',
        support: ['support'],
        goal: ['engagement'],
        channel: ['email']
    },
    {
        name: 'Triggered email Automation: Tour Alert',
        support: ['support'],
        goal: ['engagement'],
        channel: ['email']
    },
    {
        name: 'Triggered email Automation: Abandoned Cart / Browse',
        support: ['support'],
        goal: ['engagement'],
        channel: ['email']
    },
    {
        name: 'Triggered email Automation: Birthday',
        support: ['support'],
        goal: ['engagement'],
        channel: ['email']
    },
    {
        name: 'Triggered email automations: Welcome',
        support: ['support'],
        goal: ['engagement'],
        channel: ['email']
    },
    {
        name: 'Audience Sharing to Socials',
        support: ['support'],
        goal: ['engagement'],
        channel: ['social-media']
    },
    {
        name: 'Facebook Bots',
        support: ['support'],
        goal: ['engagement'],
        channel: ['social-media']
    },
    {
        name: 'Facebook Messenger',
        support: ['support'],
        goal: ['engagement'],
        channel: ['social-media']
    },
    {
        name: 'Instagram Messenger',
        support: ['support'],
        goal: ['engagement'],
        channel: ['social-media']
    },
    {
        name: 'Instagram Bots',
        support: ['support'],
        goal: ['engagement'],
        channel: ['social-media']
    },
    // Support: Ecommerce
    {
        name: 'Community',
        support: ['support'],
        goal: ['ecommerce'],
        channel: ['sms'],
        productFamily: 'community'
    },
    {
        name: 'Global Audience',
        support: ['support'],
        goal: ['ecommerce'],
        channel: ['in-venue', 'paid-campaigns']
    },
    {
        name: 'Paid Campaigns',
        support: ['support'],
        goal: ['ecommerce'],
        channel: ['social-media']
    },
    {
        name: 'Facebook Shopping',
        support: ['support'],
        goal: ['ecommerce'],
        channel: ['social-media']
    },
    {
        name: 'Youtube Shopping',
        support: ['support'],
        goal: ['ecommerce'],
        channel: ['social-media']
    },
    {
        name: 'Instagram Shopping',
        support: ['support'],
        goal: ['ecommerce'],
        channel: ['social-media']
    },
    {
        name: 'Web Store',
        support: ['support'],
        goal: ['ecommerce'],
        channel: ['web']
    },
    {
        name: 'Personalized Concert Alerts',
        support: ['support'],
        goal: ['ecommerce'],
        channel: ['email']
    },
    {
        name: 'Post-purchase nurturing campaign',
        support: ['support'],
        goal: ['ecommerce'],
        channel: ['email']
    },
    {
        name: 'Triggered email Automation: Abandoned Cart / Browse',
        support: ['support'],
        goal: ['ecommerce'],
        channel: ['email']
    },
    {
        name: 'Ecommerce NPN campaign',
        support: ['support'],
        goal: ['ecommerce'],
        channel: ['email']
    },
    // Support: Streaming
    //

    // Support: Acquisition
    {
        name: 'Instagram Messenger Bots',
        support: ['support'],
        goal: ['acquisition'],
        channel: ['social-media']
    },
    {
        name: 'Facebook Messenger Bots',
        support: ['support'],
        goal: ['acquisition'],
        channel: ['social-media']
    },
    {
        name: 'Lightbox / Banner Pop-ups',
        support: ['support'],
        goal: ['acquisition'],
        channel: ['web']
    },
    {
        name: 'SDK',
        support: ['support', 'self'],
        goal: ['acquisition', 'streaming'],
        channel: ['web'],
        productFamily: 'wmc',
        type: 'Track or Album Pre-save, Email Sign-Up, Follow an artist, track, album or playlist',
        requirements: 'WMG Log-in Details',
        benefits: 'Acquisition, Incentivise, Gamify, Streaming, Conversion'
    },
    {
        name: 'Data Integrations',
        support: ['support'],
        goal: ['acquisition'],
        channel: ['email']
    },
    // Self: Ecommerce
    {
        name: 'Follow for Discount',
        support: ['self'],
        goal: ['ecommerce'],
        channel: ['web'],
        productFamily: 'wmc',
        image: wmcProductFollow,
        type: 'Fans can follow an artist and receive a discount code in return',
        requirements: 'WMG log-in Details',
        benefits: 'Conversion, Fan-incentive, Streaming'
    },
    {
        name: 'Music Downloads',
        support: ['self'],
        goal: ['ecommerce'],
        channel: ['web'],
        productFamily: 'wmc',
        image: wmcProductRedeemableDownload,
        type: 'Fans can redeem an online download from a music purchase',
        requirements: 'WMG log-in Details',
        benefits: 'Conversion, Fan-incentive'
    },
    {
        name: 'Instagram Bio-link',
        support: ['self'],
        goal: ['engagement'],
        channel: ['social-media'],
        type: 'Promote your artist\'s key moments with a Linktree Biopage',
        requirements: 'Instagram Messenger Log-in Details ',
        benefits: 'Engagement, Streaming, Conversion'
    },
    {
        name: 'Listening History Audience Builder + Sends',
        support: ['self'],
        goal: ['engagement', 'streaming'],
        channel: ['social-media', 'email'],
        productFamily: 'wmc',
        type: 'Streaming Analysis',
        requirements: 'WMG Log-in Details',
        benefits: 'Insights'
    },
    {
        name: 'SMS Campaigns',
        support: ['self'],
        goal: ['engagement'],
        channel: ['sms'],
        productFamily: 'community',
        type: 'Mobile Acquisition and SMS Campaigns',
        requirements: 'WMG Log-in Details',
        benefits: 'Acquisition, Engagement, Conversion'
    },
    {
        name: 'Biopages',
        support: ['self'],
        goal: ['engagement'],
        channel: ['web'],
        productFamily: 'linkfire',
        type: 'Promote your artist\'s key moments with a Linktree Biopage',
        requirements: 'Log-in Details',
        benefits: 'Engagement, Streaming, Conversion'

    },
    {
        name: 'Pre-save reminders',
        support: ['self'],
        goal: ['engagement', 'streaming'],
        channel: ['email', 'social-media'],
        productFamily: 'wmc',
        type: 'Boost your streaming conversion rate from a pre-save campaign',
        requirements: 'WMG Log-in Details ',
        benefits: 'Engagement, Streaming, Conversion'
    },
    {
        name: 'Training',
        support: ['self'],
        goal: ['engagement', 'streaming', 'acquisition', 'analysis'],
        channel: ['email', 'web'],
        productFamily: 'salesforce',
        type: 'Acquire skills to build, send and track emails on Salesforce Marketing Cloud',
        requirements: 'WMG Log-in Details or OKTA',
        benefits: 'Engagement, Conversion'
    },
    {
        name: 'Training',
        support: ['self'],
        goal: ['streaming'],
        channel: ['web'],
        productFamily: 'wmc',
        type: 'Acquire skills to build campaign journeys from acquisition to conversion',
        requirements: 'WMG Log-in Details',
        benefits: 'Acquisition, Engagement, Conversion, Streaming, Analytic'
    },
    {
        name: 'Listening Party Tool',
        support: ['self'],
        goal: ['streaming'],
        channel: ['web'],
        type: 'Build a listening campaign for fans to livestream with artists',
        requirements: 'WMG Log-in Details',
        benefits: 'Acquisition, Engagement, Streaming'
    },
    {
        name: 'Release',
        support: ['self'],
        goal: ['streaming', 'acquisition'],
        channel: ['web'],
        productFamily: 'linkfire',
        image: linkfireProductPreRelease,
        type: 'Track or Album Release',
        requirements: 'Log-in Details',
        benefits: 'Acquisition, Streaming'
    },
    {
        name: 'Pre-release',
        support: ['self'],
        goal: ['streaming', 'acquisition'],
        channel: ['web'],
        productFamily: 'linkfire'
    },
    {
        name: 'Follow (To Win)',
        support: ['self'],
        goal: ['streaming', 'acquisition'],
        channel: ['web'],
        productFamily: 'wmc',
        image: wmcProductFollow,
        type: 'Follow a track / album / artist or playlist campaign',
        requirements: 'WMG Log-in Details',
        benefits: 'Acquisition, Incentivise, Streaming, Conversion'
    },
    {
        name: 'Form (Sign-up To Win)',
        support: ['self'],
        goal: ['streaming', 'acquisition'],
        channel: ['web'],
        productFamily: 'wmc',
        image: wmcProductForm,
        type: 'Email Sign-Up',
        requirements: 'WMG Log-in Details',
        benefits: 'Acquisition'
    },
    {
        name: 'Pre-save (To Win)',
        support: ['self'],
        goal: ['streaming', 'acquisition'],
        channel: ['web'],
        productFamily: 'wmc',
        image: wmcProductPreSave,
        type: 'Track or Album Pre-save Campaign',
        requirements: 'WMG Log-in Details',
        benefits: 'Acquisition, Incentivise, Streaming, Conversion'
    },
    {
        name: 'Community',
        support: ['self'],
        goal: ['acquisition'],
        channel: ['sms'],
        productFamily: 'community'
    },
    {
        name: 'Listening Histroy',
        support: ['self'],
        goal: ['analysis'],
        channel: ['web'],
        productFamily: 'wmc'
    },
    {
        name: 'Tableau CRM Dashboard',
        support: ['self'],
        goal: ['analysis'],
        channel: ['web', 'email'],
        productFamily: 'tableau',
        type: 'CRM Data Analysis',
        requirements: 'OKTA Log-in Details',
        benefits: 'Analysis, CRM'
    },
    {
        name: 'Stats Pages',
        support: ['self'],
        goal: ['analysis'],
        channel: ['web'],
        productFamily: 'wmc',
        type: 'Campaign Analysis',
        requirements: 'WMG Log-in Details',
        benefits: 'Insights, Analysis, Stream, Web & Acquisition'
    },
    {
        name: 'Stats Pages',
        support: ['self'],
        goal: ['analysis'],
        channel: ['web'],
        productFamily: 'linkfire',
        type: 'Link Analysis',
        requirements: 'WMG Log-in Details',
        benefits: 'Dashboards, Insights, Reports'
    },

];

const HomeIndex = () => {
    const [productModalShow, setProductModalShow] = useState(null)

    const [filterData, setFilterData] = useState({
        support: acquisitionSupportTypes,
        goals: acquisitionGoalTypes,
        channels: acquisitionChannelTypes,
    });

    const toggleFilter = (type, index) => (event) => {
        var filterDataNew = {...filterData};
        filterDataNew[type][index]['selected'] = filterDataNew[type][index]['selected'] === true ? false : true;

        setFilterData(filterDataNew);
    }

    const history = useHistory();

    
    useEffect(() => {
        history.push('/campaigns');
    }, []);

    
    useTitle('Dashboard');

    var selectedSupport = [];
    var selectedGoals = [];
    var selectedChannels = [];
        
    filterData.support.filter(item => item.selected).forEach( item => selectedSupport.push(item.key) );
    filterData.goals.filter(item => item.selected).forEach( item => selectedGoals.push(item.key) );
    filterData.channels.filter(item => item.selected).forEach( item => selectedChannels.push(item.key) );

    return (
        <>
            <Hero image={CampaignsHeroImage}>
                <HeroText>
                    <Typography variant="h4" sx={{mb: 1}}>Welcome</Typography>
                    <Typography paragraph>Let us know what you're trying to do and we'll do our best to point you in the right direction.</Typography>
                    <Typography variant="button"><MLink component={Link} to="/resources">Learn More</MLink></Typography>
                </HeroText>
            </Hero>
            <PageContainer>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <Paper sx={{ mr: 1 }}>
                            <Typography variant="h5" sx={{pl: 2, pt: 2}}>Support Level</Typography>

                            <Grid container spacing={1} sx={{p: 2}}>
                            {filterData.support.map((item, itemIndex) => (
                                <Grid key={itemIndex} item xs={6}>
                                    <ToggleButtonGroup fullWidth={true} sx={{width: '100%'}} color="success" key={itemIndex} value={item.selected}>
                                        <ToggleButton value={true} onClick={toggleFilter('support', itemIndex)}>{item.name}</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            ))}
                            </Grid>

                            <Typography variant="h5" sx={{pl: 2, pt: 2}}>Goal</Typography>

                            <Grid container spacing={1} sx={{p: 2}}>
                            {filterData.goals.map((item, itemIndex) => (
                                <Grid key={itemIndex} item xs={6}>
                                    <ToggleButtonGroup fullWidth={true} color="primary" key={itemIndex} value={item.selected}>
                                        <ToggleButton value={true} onClick={toggleFilter('goals', itemIndex)}>{item.name}</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            ))}
                            </Grid>

                            <Typography variant="h5" sx={{pl: 2, pt: 2}}>Channel</Typography>

                            <Grid container spacing={1} sx={{p: 2}}>
                            {filterData.channels.map((item, itemIndex) => (
                                <Grid key={itemIndex} item xs={6}>
                                    <ToggleButtonGroup fullWidth={true} color="warning" key={itemIndex} value={item.selected}>
                                        <ToggleButton value={true} onClick={toggleFilter('channels', itemIndex)}>{item.name}</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>
                            ))}
                            </Grid>
    
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={1}>
{
    acquisitionTypes
    .filter( (acquisitionType, acquisitionTypeIndex) => {
        var foundSupport = acquisitionType.support.some(r => selectedSupport.includes(r));
        var foundGoals = acquisitionType.goal.some(r => selectedGoals.includes(r));
        var foundChannels = acquisitionType.channel.some(r => selectedChannels.includes(r));

        if( ( selectedSupport.length > 0 ) && ( foundSupport === false ) )
        {
            return false;
        }

        if( ( selectedGoals.length > 0 ) && ( foundGoals === false ) )
        {
            return false;
        }

        if( ( selectedChannels.length > 0 ) && ( foundChannels === false ) )
        {
            return false;
        }

        return true;
    })
    .map( (acquisitionType, acquisitionTypeIndex) => {
        //backgroundColor: acquisitionGoalTypes.find(item => item.key === acquisitionType.goal[0]).color}}
        var productFamily = null;

        if( acquisitionType.productFamily )
        {
            productFamily = productFamilies.find(item => item.key === acquisitionType.productFamily);
        }

        return (
            <Grid key={acquisitionTypeIndex} item xs={6}>
                <Card>
                    <CardHeader
                        title={acquisitionType.name}
                        subheader={productFamily ? productFamily.name : null}
                        avatar={ productFamily && productFamily.image ? <Image sx={{maxWidth: '40px'}} src={productFamily.image} /> : '' }
                    />

                    {
                        acquisitionType.image &&
                            <CardMedia
                                component="img"
                                image={acquisitionType.image}
                            />
                    }
                    <CardContent>
                        {
                            acquisitionType.type &&
                                <Typography variant="body2" color="text.secondary">
                                    <strong>Type:</strong> {acquisitionType.type}
                                </Typography>
                        }
                        {
                            acquisitionType.requirements &&
                                <Typography variant="body2" color="text.secondary">
                                    <strong>Requirements:</strong> {acquisitionType.requirements}
                                </Typography>
                        }
                        {
                            acquisitionType.benefits &&
                                <Typography variant="body2" color="text.secondary">
                                    <strong>Benefits:</strong> {acquisitionType.benefits}
                                </Typography>
                        }
                    </CardContent>

                    <CardActions disableSpacing>
                        <IconButton onClick={() => { setProductModalShow(acquisitionTypeIndex); }} aria-label="More information">
                            <InfoOutlinedIcon />
                        </IconButton>
                    </CardActions>
                    
                </Card>

                <ModalDialogue open={productModalShow === acquisitionTypeIndex} onClose={() => { setProductModalShow(null); }}>
                    <Box sx={{px: 2, pt: 2, pb: 1}}>
                        <Typography variant="h4">{acquisitionType.name}</Typography>
                        { productFamily && <Typography variant="h5">{productFamily.name}</Typography>}

                        <Box
                            sx={{mt: 2}}
                        >
                            {
                                acquisitionType.type &&
                                    <Typography variant="body2" color="text.secondary">
                                        <strong>Type:</strong> {acquisitionType.type}
                                    </Typography>
                            }
                            {
                                acquisitionType.requirements &&
                                    <Typography variant="body2" color="text.secondary">
                                        <strong>Requirements:</strong> {acquisitionType.requirements}
                                    </Typography>
                            }
                            {
                                acquisitionType.benefits &&
                                    <Typography variant="body2" color="text.secondary">
                                        <strong>Benefits:</strong> {acquisitionType.benefits}
                                    </Typography>
                            }
                        </Box>
                    </Box>
                </ModalDialogue>

            </Grid>
        )
    })
}
                        </Grid>
                    </Grid>
                </Grid>
            </PageContainer>
        </>
    );

};

export default HomeIndex;