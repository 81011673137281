import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1300,
        },
    },
    typography: {
        fontFamily: [
            '"Suisse Int\'l Book"',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        body1: {
        },
        body2: {
        },
        h1: {
            fontWeight: 400
        },
        h2: {
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%'
        },
        subtitle2: {
            color: 'rgba(0, 0, 0, 0.6)'
        }
    },
    palette: {
        type: 'light',
        primary: {
            main: '#46bee6',
        },
        secondary: {
            main: '#ffff02',
            dark: '#e7e700',
            light: '#ffff1b',
            contrastText: '#46BEE6'
        },
        neutral: {
            main: '#272b2c',
            dark: '#000000',
            light: '#3b4142',
            contrastText: '#46bee6'
        },
        light: {
            main: '#ffffff',
        },
        dark: {
            main: '#000000',
        },
        background: {
            default: "#e0f4fb"
        },
        text: {

        }
    },
    overrides: {
      shape: {
        borderRadius: 0,
      }
    },
    components: {
        MuiInputAdornment: {
            styleOverrides: {
                root:{
                    '& .MuiTypography-root': {
                        color: 'rgba(0, 0, 0, 0.87)'
                    }
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root:{
                    color: 'rgba(0, 0, 0, 0.87)'
                }
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root:{
                    
                }
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root:{

                    '& .MuiSvgIcon-root': {
                        marginBottom: '-3px'
                    },
                    '&.MuiInputLabel-shrink': {
                        color: 'rgba(0, 0, 0, 0.87)',
                        textTransform: 'uppercase',
                    }

                }
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root:{
                    color: 'rgba(0, 0, 0, 0.87)'
                }
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        boxShadow: 'none'
                    }
                },
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    fontSize: '1rem'
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    overflowX: 'visible',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: 'none',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#000000',
                },
            },
        },
        MuiModal:{
        },
        MuiMenu:{
            defaultProps:{
                disableScrollLock: true,
            }
        },
        MuiMenuItem:{
            styleOverrides: {
                root: {
                    minWidth: '300px'
                }
            }
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    text: {
                        fill: '#ffffff',
                      },
                }
            }
        },
        MuiTimePicker: {
            defaultProps: {
                inputFormat: "HH:mm"
            }
        },
        MuiDateTimePicker: {
            defaultProps: {
                inputFormat: "dd/MM/yyyy HH:mm"
            }
        },
        MuiDrawer: {
            defaultProps:{
                disableScrollLock: true,
            },
            styleOverrides: {
                paper: {
                    background: '#272b2c',
                    color: '#ffffff'
                },
            },
        },
    }
});

export default theme;