import * as React from 'react';
import Box from '@mui/material/Box';

export default function ContentBox(props) {
  let _props = Object.assign({}, props);

  _props.sx = Object.assign({borderRadius: '5px', overflow: 'hidden', background: _props.light ? 'transparent' : '#ffffff', mt: props.margin ? 2 : 0, mb: props.margin ? 6 : 0, ml: 0, mr: 0, p: _props.light ? 0 : 2}, _props.sx);
  //_props.sx = Object.assign({borderRadius: '5px', overflow: 'hidden', background: '#ffffff', boxShadow: '0 6px #cfdadd', mt: props.margin ? 2 : 0, mb: props.margin ? 6 : 0, ml: 0, mr: 0, p: 2}, _props.sx);

  return (
    <Box {..._props}>{props.children}</Box>
  );
}
