import HomeIndex from '../Components/Pages/Home/Index';
import HomeDashboard from '../Components/Pages/Home/Dashboard';
import AccountIndex from '../Components/Pages/Account/Index';
import ReleasesIndex from '../Components/Pages/Releases/Index';
import CampaignsIndex from '../Components/Pages/Campaigns/Index';
import CampaignsDashboard from '../Components/Pages/Campaigns/Dashboard';
import TranslationsCampaigns from '../Components/Pages/Translations/Campaigns';
import CampaignsStats from '../Components/Pages/Campaigns/Stats';
import CampaignsEdit from '../Components/Pages/Campaigns/Edit';
import ResourcesIndex from '../Components/Pages/Resources/Index';
import InsightsIndex from '../Components/Pages/Insights/Index';
import InsightsStats from '../Components/Pages/Insights/Stats';
import InsightsAudienceBuilder from '../Components/Pages/Insights/AudienceBuilder';
import UsersIndex from '../Components/Pages/Users/Index';
import TeamsIndex from '../Components/Pages/Teams/Index';
import CountriesIndex from '../Components/Pages/Countries/Index';
import ResourcesCampaignSDK from '../Components/Pages/Resources/CampaignSDK';

const routes = [
    {path: "/", name: "Dashboard", Component: HomeIndex, isPublic: false},
    {path: "/dashboard", name: "Dashboard", Component: HomeDashboard, isPublic: false, exact: false},
    {path: "/account", name: "Account", Component: AccountIndex},
    {path: "/releases", name: "Releases", Component: ReleasesIndex},
    {path: "/campaigns/dashboard", name: "Campaigns: Dashboard", Component: CampaignsDashboard},
    {path: "/campaigns/:selectedCampaignType?", name: "Campaigns", Component: CampaignsIndex},
    {path: "/campaigns/:campaignId/stats", name: "Campaign: Stats", Component: CampaignsStats},
    {path: "/campaigns/:campaignId/edit/:campaignLocaleId?/:campaignTranslationId?", name: "Campaign: Edit", Component: CampaignsEdit},
    {path: "/teams", name: "Teams", Component: TeamsIndex, exact: false},
    {path: "/users", name: "Users", Component: UsersIndex, exact: false},
    {path: "/countries", name: "Countries", Component: CountriesIndex, exact: false},
    {path: "/translations/campaigns", name: "Campaigns: Translations", Component: TranslationsCampaigns, exact: false},
    {path: "/insights", name: "Insights", Component: InsightsIndex},
    {path: "/insights/:audienceId/stats", name: "Insights", Component: InsightsStats},
    {path: "/insights/audience-builder", name: "Audience Builder", Component: InsightsAudienceBuilder},
    {path: "/resources", name: "Resources: Getting Started", Component: ResourcesIndex},
    {path: "/resources/campaign-sdk", name: "Resources: Campaign SDK", Component: ResourcesCampaignSDK}
];

export default routes
