import React, { Fragment, useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import { Utilities, AcquisitionRepository } from '@warnerconnect/library';
import { Box } from '@mui/system';
import { useAuthState } from '../../../../Context';

export default function EntrantSearch(props) {
    const [fieldValue, setFieldValue] = useState();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [searchHandler, setSearchHandler] = useState();
    const [searchValue, setSearchValue] = useState();
    const [searchLoading, setSearchLoading] = useState();
    const userDetails = useAuthState();

    let acquisitionRepository = new AcquisitionRepository();
    acquisitionRepository.setBearerToken(userDetails.accessToken);

    const handleSearchChange = (event, newValue) => {
        setSearchValue(event.target.value);
    }

    const handleChange = (event, newValue) => {
        setSearchValue('');

        if( props.onChange )
        {
            props.onChange(event, newValue);
        }

    }

    useEffect(() => {
        if( searchHandler )
        {
            searchHandler.abort();
        }

        if( searchValue && ( searchValue.length >= 3 ) )
        {
            setSearchLoading(true);

            var searchAbortController = new AbortController();

            setSearchHandler(searchAbortController);

            let criteria = {};

            criteria[props.field || 'keywords'] = searchValue;

            if( props.source )
            {
                criteria['source'] = props.source;
            }

            acquisitionRepository
                .getEntrants({limit: 100}, criteria, {signal: searchAbortController.signal})
                .then( (response) => {
                    var options = [];

                    if( response.getTotal() )
                    {
                        response.getItems().forEach( (userSingle) => {
                            options.push({
                                label: userSingle.getEmail(),
                                id: userSingle.getId()
                            });
                        });
                    }

                    setOptions(options);
                    setSearchLoading(false);
                })
                .catch(e => {
                    // handle search being aborted
                });
        }

    }, [searchValue]);

    useEffect(() => {
        if (!open) {
        setOptions([]);
        }
    }, [open]);

    return (
        <Autocomplete
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            noOptionsText="Start typing to search"
            options={options}
            multiple={props.hasOwnProperty('multiple') ? ( props.multiple === undefined ? true : props.multiple ) : false}
            loading={searchLoading}
            inputValue={searchValue || ''}
            onChange={handleChange}
            renderOption={(props, option, index) => {
                const key = `listItem-${index}-${option.id}`;
                return (
                    <li {...props} key={key}>
                        {option.label}
                    </li>
                );
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={props.placeholder}
                    onChange={handleSearchChange}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                            {searchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}
