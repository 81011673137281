let exactTargetGenres = [
  {id: 'Alt_Rock', label :'Alternative Rock'},
  {id: 'Childrens_Music', label :'Childrens Music'},
  {id: 'Classical', label :'Classical'},
  {id: 'Classic Rock', label :'Classic Rock'},
  {id: 'Country', label :'Country'},
  {id: 'Country-Americana', label :'Country-Americana'},
  {id: 'Hard Rock', label :'Hard Rock'},
  {id: 'Rock', label :'Rock'},
  {id: 'Pop', label :'Pop'},
  {id: 'Jazz-Blues', label :'Jazz-Blues'},
  {id: 'World-Latin', label :'World-Latin'},
  {id: 'Hip Hop', label :'Hip Hop'},
  {id: 'RnB', label :'RnB'},
  {id: 'Electronic', label :'Electronic'},
  {id: 'Other', label :'Other'},
  {id: 'Worship', label :'Worship'},
  {id: 'Indie', label :'Indie'},
  {id: 'Workout', label :'Workout'},
  {id: 'Party', label :'Party'},
  {id: 'chill', label :'Chill'},
  {id: 'Mood', label :'Mood'},
  {id: 'Comedy', label :'Comedy'},
  {id: 'TopHits', label :'Top Hits'},
  {id: 'Nostalgia', label :'Nostalgia'},
  {id: 'NewMusic', label :'New Music'},
  {id: 'Metal', label :'Metal'},
  {id: 'broadway', label :'Broadway'},
  {id: 'latin', label :'Latin'},
  {id: 'punk', label :'Punk'},
  {id: 'K-Pop', label :'KPop'},
  {id: 'Afrobeat', label :'Afrobeat'},
  {id: 'Chanson', label :'Chanson'},
  {id: 'Rap', label :'Rap'}
].sort(function(a,b) { return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);} );

export default exactTargetGenres;