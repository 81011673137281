let Colors = [
    {
        borderColor: 'rgb(107, 120, 170)',
        pointBackgroundColor: 'rgb(107, 120, 170)',
        backgroundColor: 'rgba(107, 120, 170, 0.2)'
    },
    {
        borderColor: 'rgb(68, 188, 228)',
        pointBackgroundColor: 'rgb(68, 188, 228)',
        backgroundColor: 'rgba(68, 188, 228, 0.2)'
    },
    {
        borderColor: 'rgb(68, 188, 228)',
        pointBackgroundColor: 'rgb(68, 188, 228)',
        backgroundColor: 'rgba(68, 188, 228, 0.2)'
    },
    {
        borderColor: 'rgb(68, 188, 228)',
        pointBackgroundColor: 'rgb(68, 188, 228)',
        backgroundColor: 'rgba(68, 188, 228, 0.2)'
    },
    {
        borderColor: 'rgb(68, 188, 228)',
        pointBackgroundColor: 'rgb(68, 188, 228)',
        backgroundColor: 'rgba(68, 188, 228, 0.2)'
    },
    {
        borderColor: 'rgb(68, 188, 228)',
        pointBackgroundColor: 'rgb(68, 188, 228)',
        backgroundColor: 'rgba(68, 188, 228, 0.2)'
    },
]

export default Colors;
