import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';
import { Divider, ListItemIcon, ListItemText, Typography, Link as MLink } from '@mui/material';

export default function ContextMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  var Component = props.component || null;

  var _props = {...props};
  delete _props.component;

  return (
    <>
      {
        Component ?
          <Component {..._props} onClick={handleClick}></Component>
          :
          <IconButton
            onClick={handleClick}
            aria-label="more"
            aria-controls="long-menu"
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
          >
            <MoreVertIcon />
          </IconButton>
      }

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {props.options &&
        props.options.map((option, optionKey) => {
          if( option.divider )
          {
            return <Divider key={optionKey} />;
          }

          let itemProps = {};

          if( option.to )
          {
            if( option.external )
            {
              itemProps['target'] = '_blank';
              itemProps['rel'] = 'noopener noreferrer';
              itemProps['href'] = option.to;
              itemProps['component'] = MLink;
            }
            else
            {
              itemProps['to'] = option.to;
              itemProps['component'] = Link;
            }
          }

          return (
            <MenuItem
              key={optionKey}
              onClick={() => {
                if( option.onClick ){
                  option.onClick()
                }

                handleClose();
              }}
              {...itemProps}
            >
              {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
              {option.label && <ListItemText>{option.label}</ListItemText>}
              {option.annotation && <>{option.annotation}</>}
            </MenuItem>
          )
        
        })}
      </Menu>
    </>
  );
}
