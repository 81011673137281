import * as React from 'react';

import Grid from '@mui/material/Grid';

export default function Fieldset(props) {
  return (
    <Grid container p={2} spacing={4} {...props}>
      {props.children}
    </Grid>
  );
}