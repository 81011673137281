import React, { useState } from 'react';

import { Grid, Box, Typography, ButtonBase, Tooltip, Button } from '@mui/material';

import Image from 'mui-image'

import {
  Edit as EditIcon,
} from '@mui/icons-material';

export default function CampaignTypeSelector(props) {

  var perLine = props.perLine || 4;

  const [value, setValue] = useState(props.value);

  const handleChange = (newValue) => (e) => {
    setValue(newValue);

    if( props.onChange )
    {
      props.onChange(newValue);
    }
  };

  return (
    <Grid container onClick={props.onClick || null} sx={{...props.sx, backgroundColor: '#000000', position: 'relative'}} columns={perLine}>
    {
      ( !props.expanded && !props.transitioning ) &&
        <>
          <Box sx={{position:'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 100}}><Box sx={{bottom: '-54px', position: 'absolute'}}><Button endIcon={<EditIcon />} variant="outlined">Change Type</Button></Box></Box>
        </>
    }
    {
      props.items &&
        props.items.map( (item, itemIndex) => {
          var selected = value === item.value;

          return (
            <Tooltip key={itemIndex} title={item.description} placement="top">
              <Grid
                component={ButtonBase}
                onClick={handleChange(item.value)}
                item
                sx={{
                  backgroundColor: item.backgroundColor,
                  height: '100%;',
                  position: 'relative',
                  paddingBottom: ( 100 / perLine )+'%',
                  transition: 'opacity 250ms',
                  opacity: selected ? 1 : 0.6,
                  '&:hover': {
                    opacity: 1,
                    cursor: 'pointer',
                    backgroundColor: item.backgroundColor
                  },
                  '& p': {
                    textTransform: 'uppercase',
                    marginTop: 2
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: !props.expanded && selected ? '2.4rem' : '5rem',
                    transition: 'font-size 1s'
                  },
                  '& .MuiTouchRipple-child': {
                    backgroundColor: '#000000',
                  },
                }}
                xs={1}
              >
                  <Box
                    sx={{
                      position: 'absolute',
                      padding: '12%',
                      left: 0,
                      top: 0,
                      textAlign: 'left',
                      display: ( !selected && ( !props.expanded && !props.transitioning ) ) ? 'none' : null,
                      opacity: ( selected || ( props.expanded && !props.transitioning ) ) ? 1 : 0,
                      transition: 'opacity 0.5s'
                    }}>
                    {item.icon && <>{item.icon}</>}
                    {
                      ( props.expanded && !props.transitioning ) &&
                        <>
                          {item.title && <Typography>{item.title}</Typography>}
                        </>
                    }
                  </Box>
              </Grid>
            </Tooltip>
          )
        })
    }
      </Grid>
  );
}