import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AcquisitionAPI } from '@warnerconnect/library';

import { TablePagination, Button, Typography, CircularProgress, Link as MLink, Grid, Paper, MenuList, MenuItem, ListItemText, ListItemIcon, Checkbox, Box } from '@mui/material';

import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';

import { useAuthDispatch, logout, useAuthState, changeUserTeam } from '../../../Context';

import Loader from '../../Elements/Loader';
import CampaignList from '../../Elements/CampaignList';
import useQuery from '../../Utilities/useQuery';
import useTitle from '../../Utilities/useTitle';
import ContentBox from '../../Elements/ContentBox';
import PageContainer from './../../Elements/PageContainer';
import HeroText from '../../Elements/HeroText';
import Hero from '../../Elements/Hero';
import HeroButtonGroup from '../../Elements/HeroButtonGroup';
import HeroButton from '../../Elements/HeroButton';
import TableBarChart from '../../Elements/TableBarChart';
import BarChart from '../../Elements/Chart/BarChart';
import LineChart from '../../Elements/Chart/LineChart';

import CampaignsHeroImage from '../../../assets/media/hero/campaigns.jpg';

export default function ResourcesCampaignSDK () {
    useTitle(process.env.REACT_APP_WEBSITE_NAME+' SDK');

    return (
        <>
            <Hero image={CampaignsHeroImage}>
                <HeroText>
                    <Typography variant="h4" sx={{mb: 1}}>Campaign SDK</Typography>
                    <Typography paragraph>You can use the Campaign SDK to create a custom campaign that leverages all of the great features of our campaigns platform, such as DSP & 3rd party authentication as well as integration with ExactTarget and CDC.</Typography>
                </HeroText>
            </Hero>
            <PageContainer>

                <Typography variant="h4">Sample Code</Typography>

                <ContentBox margin={true}>

                    <p>This example below uses Topsify's Christmas 2018 campaign but you can apply these methods to any campaign. You can get the code specific to your campaign from the "Integration Options" page, when viewing your campaign's stats page.</p>
                    

                    <pre>
                    {'<button type="button" class="wmg-button" data-platform="spotify">Custom Spotify Button</button>\n'+
                    '<button type="button" class="wmg-button" data-platform="apple">Custom Apple Music Button</button>\n'+
                    '<button type="button" class="wmg-button" data-platform="deezer">Custom Deezer Button</button>\n'+
                    '\n'+
                    '<script type="text/javascript" src="'+process.env.REACT_APP_FRONTEND_URL+'/app/sdk/v2/platform.js?campaign=11784"></script>\n'+
                    '<script type="text/javascript">\n'+
                    'var wmg_platform = new WMGConnect(".wmg-button", {\n'+
                    '   // Would you like a popup "Thank you" message to automatically appear once the user has been acquired?\n'+
                    '   opt_in_thank_you_enabled: true\n'+
                    '}\n'+
                    '\n'+
                    'wmg_platform.setCallback(function(connect_data){\n'+
                    '   // Callback code\n'+
                    '   console.log(connect_data);\n'+
                    '});\n'+
                    '</script>'}
                    
                    </pre>
                </ContentBox>

                <Typography variant="h4">Methods</Typography>
                
                <ContentBox margin={true}>

                    <table>
                        <thead>
                            <tr>
                                <th sx={{width: '30%'}}>Method</th>
                                <th sx={{width: '70%'}}>Description</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td><code>wmg_platform.campaign.getData()</code></td>
                                <td>Get the campaign data, such as the campaign name.</td>
                            </tr>
                            <tr>
                                <td><code>wmg_platform.campaign.getTotalStreams(callback)</code></td>
                                <td>Get the total streams generated by this campaign. Only applies to pre-save or stream-to-enter campaigns.<br /><br /><code>callback</code> parameter is executed once the API has returned data.</td>
                            </tr>
                            <tr>
                                <td><code>wmg_platform.campaign.getStreamsUserLeaderboard(callback)</code></td>
                                <td>Get the total streams generated by this campaign, broken down by user. Only applies to pre-save or stream-to-enter campaigns.<br /><br /><code>callback</code> parameter is executed once the API has returned data.</td>
                            </tr>
                            <tr>
                                <td><code>wmg_platform.campaign.getStreamsCountryLeaderboard(callback)</code></td>
                                <td>Get the total streams generated by this campaign, broken down by country. Only applies to pre-save or stream-to-enter campaigns.<br /><br /><code>callback</code> parameter is executed once the API has returned data.</td>
                            </tr>
                            <tr>
                                <td><code>wmg_platform.campaign.getStreamsRegionLeaderboard(callback)</code></td>
                                <td>Get the total streams generated by this campaign, broken down by region. Only applies to pre-save or stream-to-enter campaigns.<br /><br /><code>callback</code> parameter is executed once the API has returned data.</td>
                            </tr>

                            <tr>
                                <td><code>wmg_platform.user.spotify.followPlaylist(playlist_id, callback)</code></td>
                                <td>Have 
                                    <br /><br /><code>playlist_id</code> - ID of playlist you'd like the user to follow.
                                    <br /><br /><code>callback</code> parameter is executed once the API has returned data.
                                </td>
                            </tr>
                            <tr>
                                <td><code>wmg_platform.user.spotify.followArtists(artist_id, callback)</code></td>
                                <td>Have the current user follow an artist.
                                    <br /><br /><code>artist_id</code> - ID of artist you'd like the user to follow.
                                    <br /><br /><code>callback</code> parameter is executed once the API has returned data.
                                </td>
                            </tr>
                            <tr>
                                <td><code>wmg_platform.user.spotify.currentUserFollowsArtists([artist_ids], callback)</code></td>
                                <td>Check if the current user follows a list of artists
                                    <br /><br /><code>artist_id</code> - IDs of artists you'd like to check if the current users follows.
                                    <br /><br /><code>callback</code> parameter is executed once the API has returned data.
                                </td>
                            </tr>
                            <tr>
                                <td><code>{"wmg_platform.user.spotify.createUserPlaylist({name: 'Playlist Name', description: 'Playlist Description'}}, callback)"}</code></td>
                                <td>Create a playlist against the current user.
                                    <br /><br /><code>playlist_data</code> - JSON object containing parameters for the creation of the playlist. Matches the properties available in the <a href="https://developer.spotify.com/documentation/web-api/reference-beta/#category-playlists">official documentation</a>.
                                    <br /><br /><code>callback</code> parameter is executed once the API has returned data.
                                </td>
                            </tr>
                            <tr>
                                <td><code>wmg_platform.user.spotify.addUserPlaylistTracks(playlist_id, [track_ids], callback)</code></td>
                                <td>Adds tracks to one of the current user's playlists.
                                    <br /><br /><code>playlist_id</code> - ID of the playlist you'd like to add tracks to.
                                    <br /><br /><code>track_ids</code> - Array of track IDs you like to add to the playlist.
                                    <br /><br /><code>callback</code> parameter is executed once the API has returned data.
                                </td>
                            </tr>
                            <tr>
                                <td><code>wmg_platform.user.spotify.updatePlaylistImage(playlist_id, image_data, callback)</code></td>
                                <td>Adds custom artwork to one of the current user's playlists.
                                    <br /><br /><code>playlist_id</code> - ID of the playlist you'd like to add artwork to.
                                    <br /><br /><code>image_data</code> - Raw image data that you'd like to apply to the playlist.
                                    <br /><br /><code>callback</code> parameter is executed once the API has returned data.
                                </td>
                            </tr>
                            <tr>
                                <td><code>{"wmg_platform.user.spotify.getTop({'type': 'artists', 'time_frame': 'short_term'}, callback)"}</code></td>
                                <td>Gets the users top tracks or artists.
                                    <br /><br /><code>parameters</code> - 'tracks' or 'artists'
                                    <br /><br /><code>callback</code> parameter is executed once the API has returned data.
                                </td>
                            </tr>
                            <tr>
                                <td><code>wmg_platform.user.spotify.getRecentlyPlayed(callback)</code></td>
                                <td>Gets the users recently played tracks.
                                    <br /><br /><code>callback</code> parameter is executed once the API has returned data.
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </ContentBox>
            </PageContainer>
        </>
    );

};
