import React, { Fragment, useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import { Utilities, SpotifyAPI } from '@warnerconnect/library';
import { Box } from '@mui/system';

export default function DSPSearch(props) {
    const [fieldValue, setFieldValue] = useState(props.value);
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [searchHandler, setSearchHandler] = useState();
    const [searchValue, setSearchValue] = useState(props.value);
    const [searchLoading, setSearchLoading] = useState();

    let spotifyAPI = new SpotifyAPI();

    const handleSearchChange = event => {
        setSearchValue(event.target.value);
    }

    const handleChange = (event, newValues) => {
        var newValuesFlat = [];

        newValues.forEach(newValue => {
            newValuesFlat.push(newValue);
        });

        setFieldValue(newValuesFlat);

        if( props.onChange )
        {
            props.onChange(event, newValuesFlat);
        }
    }

    useEffect(() => {
        if( searchHandler )
        {
            searchHandler.abort();
        }

        if( searchValue && ( searchValue.length >= 3 ) )
        {
            setSearchLoading(true);

            var searchAbortController = new AbortController();

            setSearchHandler(searchAbortController);

            spotifyAPI
                .search(searchValue, props.type, {}, {signal: searchAbortController.signal})
                .then( (response) => {
                    var options = [];

                    if( response.playlists && response.playlists.items )
                    {
                        response.playlists.items.forEach( ({id, name, images, owner, external_urls}) => {
                            options.push({
                                image: ( images && images[0] ) ? images[0].url : null,
                                label: name+' by '+owner.display_name,
                                id: id,
                                uri: external_urls.spotify
                            });
                        });
                    }
                    else if( response.artists && response.artists.items )
                    {
                        response.artists.items.forEach( ({id, name, images, external_urls}) => {
                            options.push({
                                image: ( images && images[0] ) ? images[0].url : null,
                                label: name,
                                id: id,
                                uri: external_urls.spotify
                            });
                        });
                    }
                    else if( response.tracks && response.tracks.items )
                    {
                        response.tracks.items.forEach( ({id, name, artists, images, external_urls}) => {
                            options.push({
                                image: ( images && images[0] ) ? images[0].url : null,
                                label: name+' by '+artists[0].name,
                                id: id,
                                uri: external_urls.spotify
                            });
                        });
                    }
                    else if( response.albums && response.albums.items )
                    {
                        response.albums.items.forEach( ({id, name, artists, album_type, images, external_urls}) => {
                            var type;
                            if( album_type === 'single' )       type = 'Single';
                            else if( album_type === 'album' )       type = 'Album';
                            else if( album_type === 'compilation' )       type = 'Compilation';

                            options.push({
                                image: ( images && images[0] ) ? images[0].url : null,
                                label: name+' by '+artists[0].name+ ' ('+type+')',
                                id: id,
                                uri: external_urls.spotify
                            });
                        });
                    }

                    setSearchLoading(false);

                    setOptions(options);
                })
                .catch(e => {
                    // handle search being aborted
                });
        }
        
    }, [searchValue]);

    useEffect(() => {
        if (!open) {
        setOptions([]);
        }
    }, [open]);

    useEffect(() => {
        setFieldValue(props.value);
    }, [props.value]);

    return (
        <Autocomplete
            id={props.id || null}
            name={props.name || null}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            multiple={props.hasOwnProperty('multiple') ? props.multiple : true}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.label}
            noOptionsText="Start typing to search"
            options={options}
            loading={searchLoading}
            value={fieldValue}
            onChange={handleChange}
            renderOption={(props, option, index) => {
                const key = `listItem-${index}-${option.id}`;
                return (
                    <li {...props} key={key}>
                        {option.label}
                    </li>
                );
            }}
            renderInput={(params) => {
                return <TextField
                    {...params}
                    placeholder={fieldValue.length > 0 ? '' : props.placeholder || 'All'}
                    onChange={handleSearchChange}
                    InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                        {searchLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                    }}
                />
            }}
        />
    );
}
