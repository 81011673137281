import { SnackbarProvider } from "notistack";

export function Notifications({ children }) {
  return (
    <SnackbarProvider
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      {children}
    </SnackbarProvider>
  );
}
