import React, { useState, useEffect, useReducer } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AcquisitionRepository, AcquisitionAPI } from '@warnerconnect/library';

import { TablePagination, Box, InputLabel, Tooltip, InputAdornment, Select, TextField, MenuItem, Button, Typography, CircularProgress, Link as MLink, Checkbox, ListItemText, OutlinedInput, Autocomplete, FormControlLabel, Switch } from '@mui/material';

import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';

import { useAuthDispatch, logout, useAuthState, changeCountryCountry } from '../../../Context';
import { formReducer } from '../../../Context/formReducer';
import { useSnackbar } from "notistack";

import {
    Info as InfoIcon,
    AddBox as AddBoxIcon,
    Save as SaveIcon,
    ArrowBack as ArrowBackIcon
} from '@mui/icons-material';

import CountryList from '../../Elements/CountryList';

import useQuery from '../../Utilities/useQuery';
import useTitle from '../../Utilities/useTitle';
import FieldContainer from '../../Elements/Forms/FieldContainer';
import Fieldset from '../../Elements/Forms/Fieldset';
import ContentBox from '../../Elements/ContentBox';
import ModalDialogue from '../../Elements/ModalDialogue';
import PageContainer from './../../Elements/PageContainer';
import CountrySelector from './../../Elements/Forms/Fields/CountrySelector';
import RecordLabelSelector from './../../Elements/Forms/Fields/RecordLabelSelector';
import HeroText from '../../Elements/HeroText';
import Hero from '../../Elements/Hero';
import HeroButtonGroup from '../../Elements/HeroButtonGroup';
import HeroButton from '../../Elements/HeroButton';
import Loader from '../../Elements/Loader';
import TabGroup from '../../Elements/TabGroup';

import CountriesHeroImage from '../../../assets/media/hero/campaigns.jpg';
import { LoadingButton } from '@mui/lab';

export default function CountriesIndex() {
    useTitle('Countries');

    let query = useQuery();
    const history = useHistory();
    const location = useLocation();

    const { enqueueSnackbar } = useSnackbar();

    const countryDetails = useAuthState();
    const userDetails = useAuthState();

    if( !query.get('page') )
    {
        query.set('page', 0)
    }

    if( !query.get('perPage') )
    {
        query.set('perPage', 25)
    }
    
    const [countryEditStep, setCountryEditStep] = useState(0);

    const [countryData, setCountryData] = useState();
    const [countryDataLoading, setCountryDataLoading] = useState(false);
    const [countryDataEvents, setCountryDataEvents] = useState(0);

    const [regionData, setRegionData] = useState();
    const [regionDataLoading, setRegionDataLoading] = useState(false);

    const acquisitionRepository = new AcquisitionRepository();
    const acquisitionAPI = new AcquisitionAPI();

    acquisitionRepository.setBearerToken(userDetails.accessToken);
    acquisitionAPI.setBearerToken(userDetails.accessToken);
    
    const [countrySearchModal, setCountrySearchModal] = useState(false);
    const [countrySearchLabelData, setCountrySearchLabelData] = useState();
    const [countrySearchLabelDataLoading, setCountrySearchLabelDataLoading] = useState(false);

    const [countryEditCountrySearchData, setCountryEditCountrySearchData] = useState({});

    const [countryEditModal, setCountryEditModal] = useState(false);
    const [countryEditLoading, setCountryEditLoading] = useState(false);
    const [countryEditSubmitting, setCountryEditSubmitting] = useState(false);

    const [countryCreateModal, setCountryCreateModal] = useState(false);
    const [countryCreateSubmitting, setCountryCreateSubmitting] = useState(false);

    const [countryCreateData, setCountryCreateData] = useReducer(formReducer, {});

    const [countryEditData, setCountryEditData] = useReducer(
        ( currentValues, newValues ) => ({...currentValues, ...newValues}), {}
    );

    const [countrySearchData, setCountrySearchData] = useReducer(
        ( currentValues, newValues ) => ({...currentValues, ...newValues}), {}
    );

    const refreshCountryData = event => {
        setCountryDataEvents(countryDataEvents + 1);
    }

    const toggleCountrySearchModal = event => {
        setCountrySearchModal(!countrySearchModal);
    }

    const toggleCountryCreateModal = event => {
        setCountryCreateData({ type: 'reset' });
        setCountryCreateModal(!countryCreateModal);
    }

    const closeCountryEditModal = event => {
        history.push('/countries');
    }
    
    const setCountryCreateDataSingle = (name, value) => {
        setCountryCreateData({ name: name, value: value});
    };

    const setCountryEditDataSingle = (name, value) => {
        setCountryEditData({ [name]: value });
    };

    const setCountrySearchDataSingle = (name, value) => {
        setCountrySearchData({ [name]: value });
    };

    const handleCountryCreateChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setCountryCreateDataSingle(
            event.target.name,
            isCheckbox ? event.target.checked : event.target.value
        );
    }

    const handleChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setCountryEditDataSingle(
            event.target.name,
            isCheckbox ? event.target.checked : event.target.value
        );
    }

    const handleSearchChange = event => {
        const isCheckbox = event.target.type === 'checkbox';

        setCountrySearchDataSingle(
            event.target.name,
            isCheckbox ? event.target.checked : event.target.value
        );
    }

    // Detect path change to hand page edit
    useEffect(() => {
        let locationParts = location.pathname.split('/');

        setCountryEditLoading(false);
        setCountryEditModal(false);
        setCountryEditData({});

        if( locationParts[2] && locationParts[3] && ( locationParts[3] === 'edit' ) )
        {
            setCountryEditLoading(true);
            setCountryEditModal(true);

            let countryId = parseInt(locationParts[2]);

            acquisitionRepository.getCountry(countryId, {include: ['region']}).then( country => {
                setCountryEditData({
                    id: country.getId(),
                    name: country.getName(),
                    a2: country.getA2(),
                    region: country.getRegion() ? country.getRegion().getId() : null,
                    position: country.getPosition()
                });

                setCountryEditLoading(false);
            });
        }
        else
        {

        }

    }, [location]);

    useEffect(() => {
        setCountryDataLoading(true);

        let limit = query.get('perPage');

        let offset = query.get('page') !== 0 ? ( query.get('page') * query.get('perPage') ) : 0;

        let criteria = countrySearchData || {};

        let options = {
            limit: limit,
            offset: offset,
            include: ['childCountries', 'countries', 'regions', 'countries']
        };

        acquisitionRepository.getCountries(options, criteria).then( response => {
            setCountryData(response);
            setCountryDataLoading(false);
        });
    }, [countryDetails.country, query.get('page'), query.get('perPage'), countryDataEvents, countrySearchData]);

    useEffect(() => {
        acquisitionRepository.getRegions().then( response => {
            setRegionData(response);
            setRegionDataLoading(false);
        });
    }, []);

    function handleChangePage(event, newValue){
        query.set('page', newValue);

        history.push(window.location.pathname+'?'+query.toString());
    }

    function handleChangeRowsPerPage(event, newValue){
        query.set('perPage', newValue.props.value);

        history.push(window.location.pathname+'?'+query.toString());
    }

    function labelDisplayedRows( { from, to, count, page } ){
        return `${from}–${to} of ${count !== -1 ? count.toLocaleString() : `more than ${to}`}`;
    }

    return (
        <>
            <ModalDialogue open={countryEditModal} size="large" onClose={closeCountryEditModal}>
                { countryEditLoading ?
                    <Loader size="x-small" />
                    :
                    <>
                        <Box sx={{px: 2, pt: 2, pb: 1}}>
                            <Typography variant="h4">Edit {countryEditData['name']}</Typography>                    
                        </Box>

                        <Fieldset>
                            <FieldContainer xs={12}>
                                <InputLabel shrink>Name</InputLabel>
                                <TextField
                                    name="name"
                                    fullWidth
                                    value={countryEditData['name'] || ''}
                                    onChange={handleChange}
                                />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink>A2 Code</InputLabel>
                                <TextField
                                    name="a2"
                                    fullWidth
                                    value={countryEditData['a2'] || ''}
                                    onChange={handleChange}
                                />
                            </FieldContainer>

                            <FieldContainer xs={6}>
                                <InputLabel shrink>Position <Tooltip title={<>This denotes where this country will appear in the list. The higher the number, the higher it will be in the list.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                                <TextField
                                    name="position"
                                    fullWidth
                                    type="number"
                                    value={countryEditData['position'] || ''}
                                    onChange={handleChange}
                                />
                            </FieldContainer>

                            <FieldContainer xs={12}>
                                <InputLabel shrink>Region</InputLabel>
                                <Select
                                    value={countryEditData['region'] || ''}
                                    name="region"
                                    fullWidth
                                    onChange={handleChange}
                                >
                                    { regionData &&
                                        regionData.getItems().map( (item) => (
                                            <MenuItem key={item.getId()} value={item.getId()}>{item.getName()}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </FieldContainer>
                            
                            <FieldContainer xs={12} sx={{textAlign: 'right'}}>
                                <LoadingButton
                                    sx={{mb: 2}}
                                    type="submit"
                                    loading={countryEditSubmitting}
                                    onClick={() => {
                                        setCountryEditSubmitting(true);

                                        acquisitionRepository
                                            .updateCountry(countryEditData['id'], {
                                                name: countryEditData['name'],
                                                a2: countryEditData['a2'],
                                                position: countryEditData['position'],
                                                region: countryEditData['region']
                                            })
                                            .then(updatedCountry => {
                                                setCountryEditSubmitting(false);

                                                enqueueSnackbar("Country "+updatedCountry.getName()+" updated", {
                                                    variant: 'success'
                                                });
        
                                                refreshCountryData();
                                            });
                                    }}
                                    loadingPosition="end"
                                    variant="contained"
                                    endIcon={<SaveIcon />}
                                >
                                    Save Changes
                                </LoadingButton>
                            </FieldContainer>
                        </Fieldset>
                                    
                    </>
                    
                }
            </ModalDialogue>

            <ModalDialogue open={countryCreateModal} size="large" onClose={toggleCountryCreateModal}>
                <Box sx={{px: 2, pt: 2, pb: 1}}>
                    <Typography variant="h4">Create Country</Typography>                    
                </Box>
                <form autoComplete="off">
                <Fieldset>
                    <FieldContainer xs={12}>
                        <InputLabel shrink>Name</InputLabel>
                        <TextField
                            name="name"
                            fullWidth
                            value={countryCreateData['name'] || ''}
                            onChange={handleCountryCreateChange}
                        />
                    </FieldContainer>

                    <FieldContainer xs={6}>
                        <InputLabel shrink>A2 Code</InputLabel>
                        <TextField
                            name="a2"
                            fullWidth
                            value={countryCreateData['a2'] || ''}
                            onChange={handleCountryCreateChange}
                        />
                    </FieldContainer>

                    <FieldContainer xs={6}>
                        <InputLabel shrink>Position <Tooltip title={<>This denotes where this country will appear in the list. The higher the number, the higher it will be in the list.</>}><InfoIcon fontSize="small" /></Tooltip></InputLabel>
                        <TextField
                            name="position"
                            fullWidth
                            type="number"
                            value={countryCreateData['position'] || ''}
                            onChange={handleCountryCreateChange}
                        />
                    </FieldContainer>

                    <FieldContainer xs={12}>
                        <InputLabel shrink>Region</InputLabel>
                        <Select
                            value={countryCreateData['region'] || ''}
                            name="region"
                            fullWidth
                            onChange={handleCountryCreateChange}
                        >
                            { regionData &&
                                regionData.getItems().map( (item) => (
                                    <MenuItem key={item.getId()} value={item.getId()}>{item.getName()}</MenuItem>
                                ))
                            }

                        </Select>
                    </FieldContainer>
                    
                    <FieldContainer xs={12} sx={{textAlign: 'right'}}>
                        <LoadingButton
                            sx={{mb: 2}}
                            type="submit"
                            loading={countryCreateSubmitting}
                            loadingPosition="end"
                            variant="contained"
                            endIcon={<AddBoxIcon />}
                            onClick={() => {
                                setCountryCreateSubmitting(true);

                                acquisitionRepository
                                    .createCountry({
                                        name: countryCreateData['name'],
                                        a2: countryCreateData['a2'],
                                        position: countryCreateData['position'],
                                        region: countryCreateData['region']
                                    })
                                    .then(newCountry => {
                                        setCountryCreateSubmitting(false);
                                        setCountryCreateModal(false);

                                        enqueueSnackbar("Country "+newCountry.getName()+" created", {
                                            variant: 'success'
                                        });

                                        refreshCountryData();

                                        history.push('/countries/'+newCountry.getId()+'/edit');
                                    });
                            }}
                        >
                            Create Country
                        </LoadingButton>
                    </FieldContainer>
                </Fieldset>
                </form>
            </ModalDialogue>

            <ModalDialogue open={countrySearchModal} size="large" onClose={toggleCountrySearchModal}>
                <Box sx={{px: 2, pt: 2, pb: 1}}>
                    <Typography variant="h4">Search</Typography>                    
                </Box>

                <Fieldset>
                    <FieldContainer xs={12}>
                        <InputLabel shrink>Keywords</InputLabel>
                        <TextField
                            name="keywords"
                            fullWidth
                            value={countrySearchData['keywords'] || ''}
                            onChange={handleSearchChange}
                        />
                    </FieldContainer>

                </Fieldset>
            </ModalDialogue>

            <Hero image={CountriesHeroImage}>
                <HeroText>
                    <Typography variant="h4" sx={{mb: 1}}>Welcome</Typography>
                    <Typography paragraph>Here you can edit existing countries and create new countries.</Typography>
                    <Typography variant="button"><MLink component={Link} to="/resources">Learn More</MLink></Typography>
                </HeroText>
                <HeroButtonGroup>
                    <HeroButton onClick={toggleCountryCreateModal} color="secondary">Create Country<AddCircleOutlineIcon /></HeroButton>
                </HeroButtonGroup>
            </Hero>
            <PageContainer>
                <Typography variant="h4">Countries</Typography>
                { countryDataLoading && <Loader /> }
                { !countryDataLoading && countryData &&
                <>
                    <CountryList
                        filter={toggleCountrySearchModal}
                        data={countryData.getItems()}
                        onDelete={countryId => {
                            return acquisitionAPI.deleteCountry(countryId)
                                .then(deleteCountryResponse => {
                                    enqueueSnackbar("Country deleted", {
                                        variant: 'success'
                                    });

                                    refreshCountryData();

                                    return deleteCountryResponse;
                                });
                        }}
                    />
                    {
                        ( countryData.getTotal() > 0 ) &&
                            <TablePagination
                                component="div"
                                count={countryData.getTotal()}
                                page={parseInt(query.get('page'))}
                                rowsPerPage={parseInt(query.get('perPage'))}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelDisplayedRows={labelDisplayedRows}
                            />
                    }
                </>
                }
            </PageContainer>
        </>
    );
};
