import { useState } from 'react';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Link } from "react-router-dom";
import MLink from '@mui/material/Link';

import ContextMenu from './ContextMenu.js';

import {
    Delete as DeleteIcon
} from '@mui/icons-material';
import { Box } from '@mui/system';
import ModalDialogue from './ModalDialogue.js';
import { Button } from '@mui/material';

export default function CampaignTranslationListSingle(props) {
    const [deleteModal, setDeleteModal] = useState(false);

    const toggleDeleteModal = event => {
        setDeleteModal(!deleteModal);
    }

    return (
        <>
            <ModalDialogue showFooter={false} open={deleteModal} onClose={toggleDeleteModal}>
                <Box sx={{px: 2, pt: 2, pb: 1}}>

                    <Typography mb={2} variant="h4">Delete Translation</Typography>
                    <Typography variant="body1">Are you sure you want to delete this campaign translation? This action cannot be undone.</Typography>

                    <Box sx={{textAlign: 'right'}}>
                        <Button
                            onClick={toggleDeleteModal}
                            sx={{mt: 2, ml: 2}}
                        >
                            Cancel
                        </Button>

                        <Button
                            onClick={() => {
                                if( props.onDelete )
                                {
                                    props.onDelete(props.data.getId()).then(deleteResponse => {
                                        toggleDeleteModal();
                                    });
                                }
                            }}
                            sx={{mt: 2, ml: 2}
                        }>
                            Delete
                        </Button>
                    </Box>

                </Box>
            </ModalDialogue>
            
            <TableRow key={props.data.getId()} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell sx={{width: '40%', paddingRight: 0}} component="th" scope="row">
                    <Typography variant="body1"><MLink component={Link} to={`/translations/campaigns/${props.data.getId()}/edit`}>{props.data.getLanguage().getName()}</MLink></Typography>
                </TableCell>
                <TableCell sx={{width: '25%', paddingRight: 0}}>
                    <Typography variant="subtitle2">{props.data.getCountry() ? props.data.getCountry().getName() : 'Global'}</Typography>
                </TableCell>
                <TableCell sx={{width: '25%', paddingRight: 0}}>
                    <Typography variant="subtitle2">{props.data.getLanguage().getA2()+( props.data.getCountry() ? '_'+props.data.getCountry().getA2() : '')}</Typography>
                </TableCell>
                <TableCell sx={{width: '10%', paddingRight: 0}}>
                    <ContextMenu
                        options={[
                            {icon: <DeleteIcon />, label: 'Delete', onClick: toggleDeleteModal},
                        ]}
                    />
                </TableCell>
            </TableRow>
        </>
    );
}