import React, { useState, useEffect } from 'react';

class Footer extends React.Component {
    render() {
        return <footer className="mt-5">
          
      </footer>
    }
}

export default Footer;