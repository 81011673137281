import React, { useState, useEffect, Fragment, useReducer } from 'react';

import { UserAPI, UserAuthAPI, UserRepository } from '@warnerconnect/library';

import {
  Save as SaveIcon
} from '@mui/icons-material';


import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useAuthDispatch, logout, useAuthState, changeUserTeam } from '../../Context';
import { Button, InputLabel, TextField } from '@mui/material';
import ModalDialogue from './ModalDialogue';
import TabGroup from './TabGroup';
import Loader from './Loader';
import Fieldset from './Forms/Fieldset';
import FieldContainer from './Forms/FieldContainer';
import { formReducer } from '../../Context/formReducer';
import { LoadingButton } from '@mui/lab';

import { useHistory } from 'react-router-dom';

import { useSnackbar } from "notistack";

export default function Header(props) {
  const [anchorTeamMenu, setAnchorTeamMenu] = useState(null);
  const [anchorUserMenu, setAnchorUserMenu] = useState(null);

  const openTeamMenu = Boolean(anchorTeamMenu);
  const openUserMenu = Boolean(anchorUserMenu);

  const userDetails = useAuthState()
	const dispatch = useAuthDispatch();

  const [teamsData, setTeamsData] = useState();
  const [teamsLoading, setTeamsDataLoading] = useState(false);

  const history = useHistory();

  const [selectedTeam, setSelectedTeam] = useState(userDetails.team);
  const [userDataSubmitting, setUserDataSubmitting] = useState(false);
  const [userDataLoading, setUserDataLoading] = useState(false);
  const [userData, setUserData] = useReducer(formReducer, {});

  const [userEditModal, setUserEditModal] = useState(false);

  const userAPI = new UserAPI();
  userAPI.setBearerToken(userDetails.accessToken);

  const userRepository = new UserRepository();
  userRepository.setBearerToken(userDetails.accessToken);

  const { enqueueSnackbar } = useSnackbar();

  const setUserDataSingle = (name, value) => {
    setUserData({ name: name, value: value});
  };

  const handleChange = event => {
      const isCheckbox = event.target.type === 'checkbox';

      setUserDataSingle(
          event.target.name,
          isCheckbox ? event.target.checked : event.target.value
      );
  }

  const toggleTeamMenu = (event) => {
    setAnchorTeamMenu(anchorTeamMenu ? null : event.currentTarget);
  };
  
  const toggleUserMenu = (event) => {
    setAnchorUserMenu(openUserMenu ? null : event.currentTarget);
  };

  const handleLogout = () => {
		logout(dispatch);
    history.push('/');
	};

  const changeSelectedTeam = (teamId) => () => {
    setSelectedTeam(teamId);
	};

  useEffect(() => {
    changeUserTeam(dispatch, selectedTeam);
  }, [selectedTeam]);

  useEffect(() => {
    if( userDetails.user )
    {
      setTeamsDataLoading(true);

      userAPI.validate().then((validTokenResponse) => {
        if(validTokenResponse === false){
          handleLogout();
        }
      });
      
      userRepository.getUserTeams(userDetails.user.id, {limit: 200, })
        .then( teams => {
          setTeamsData(teams.getItems());
          setTeamsDataLoading(false);
        });

      setUserDataLoading(true);

      userRepository.getUser(userDetails.user.id)
        .then( user => {

          setUserDataSingle('name', user.getName());
          setUserDataSingle('email', user.getEmail());
          setUserDataLoading(false);
        });
    }
  }, [userDetails.user]);

  var menuStyle = {
    PaperProps: {
      elevation: 0,
      sx: {
        width:'300px',
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        '& .MuiAvatar-root': {
          width: 32,
          height: 32,
          ml: -0.5,
          mr: 1,
        },
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 14,
          width: 10,
          height: 10,
          bgcolor: 'background.paper',
          transform: 'translateY(-50%) rotate(45deg)',
          zIndex: 0,
        },
      },
    },
    transformOrigin: { horizontal: 'right', vertical: 'top' },
    anchorOrigin: { horizontal: 'right', vertical: 'bottom' }
  }

  return (
    <>
      <ModalDialogue open={userEditModal} size="large" onClose={() => setUserEditModal(false)}>

        { userDataLoading ?
            <Loader size="x-small" />
            :
            <TabGroup
                tabList={[
                    {
                        label: 'Account Details',
                        content: <>
                            <Fieldset>
                              <FieldContainer xs={12}>
                                    <InputLabel shrink>Name</InputLabel>
                                    <TextField
                                        name="name"
                                        fullWidth
                                        value={userData['name'] || ''}
                                        onChange={handleChange}
                                    />
                                </FieldContainer>

                                <FieldContainer xs={12}>
                                    <InputLabel shrink>Email</InputLabel>
                                    <TextField
                                        name="email"
                                        fullWidth
                                        value={userData['email'] || ''}
                                        onChange={handleChange}
                                    />
                                </FieldContainer>

                                <FieldContainer xs={12} sx={{textAlign: 'right'}}>
                                    <LoadingButton
                                        sx={{mb: 2}}
                                        type="submit"
                                        loading={userDataSubmitting}
                                        onClick={() => {
                                            setUserDataSubmitting(true);

                                            userRepository
                                                .updateUser(userDetails.user.id, {
                                                    name: userData['name'],
                                                    email: userData['email']
                                                })
                                                .then(updatedUser => {
                                                  setUserDataSubmitting(false);

                                                  enqueueSnackbar("Your details have been updated", {
                                                      variant: 'success'
                                                  });
                                                });
                                        }}
                                        loadingPosition="end"
                                        variant="contained"
                                        endIcon={<SaveIcon />}
                                    >
                                        Save Changes
                                    </LoadingButton>
                                </FieldContainer>
                            </Fieldset>
                        </>
                    },
                    {
                        label: 'Change Password',
                        content: <>

                          <Fieldset>
                              <FieldContainer xs={12}>
                                    <InputLabel shrink>New Password</InputLabel>
                                    <TextField
                                        name="newPassword"
                                        type="password"
                                        fullWidth
                                        value={userData['newPassword'] || ''}
                                        onChange={handleChange}
                                    />
                                </FieldContainer>

                                <FieldContainer xs={12}>
                                    <InputLabel shrink>Confirm Password</InputLabel>
                                    <TextField
                                        name="newPasswordConfirm"
                                        type="password"
                                        fullWidth
                                        value={userData['newPasswordConfirm'] || ''}
                                        onChange={handleChange}
                                    />
                                </FieldContainer>

                                <FieldContainer xs={12} sx={{textAlign: 'right'}}>
                                    <LoadingButton
                                        sx={{mb: 2}}
                                        type="submit"
                                        loading={userDataSubmitting}
                                        disabled={!(userData['newPasswordConfirm'] && ( userData['newPassword'] === userData['newPasswordConfirm'] ))}
                                        onClick={() => {
                                          setUserDataSubmitting(true);

                                          userRepository
                                              .updateUser(userDetails.user.id, {
                                                  password: userData['newPassword']
                                              })
                                              .then(updatedUser => {
                                                setUserDataSubmitting(false);

                                                enqueueSnackbar("Your password has been updated", {
                                                    variant: 'success'
                                                });

                                              });
                                        }}
                                        loadingPosition="end"
                                        variant="contained"
                                        endIcon={<SaveIcon />}
                                    >
                                      Change Password
                                    </LoadingButton>
                                </FieldContainer>
                            </Fieldset>
                        </>
                    }
                ]}
            />
            
        }
    </ModalDialogue>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>

{
  userDetails.user &&
  <>
    { teamsData &&
    <>
      <Box sx={{ display: 'flex' }}>
        <Tooltip title="Switch your view to a different team">
          <Button endIcon={<ArrowDropDownIcon></ArrowDropDownIcon>} color="light" size="large" onClick={toggleTeamMenu} sx={{ ml: 2 }}>
            { userDetails.team ?
              teamsData.filter(team => team.getId() === userDetails.team).map(filteredTeam => (
                <Fragment key={filteredTeam.getId()}>{filteredTeam.getName()}</Fragment>
              ))
              :
              'All Teams'
            }
          
          </Button>
        </Tooltip>
      </Box>
      <Menu
        {...menuStyle}
        anchorEl={anchorTeamMenu}
        open={openTeamMenu}
        onClose={toggleTeamMenu}
        onClick={toggleTeamMenu}
      >
        <MenuItem selected={!userDetails.team} onClick={changeSelectedTeam(null)} sx={{fontWeight: 'bold'}}>All Teams</MenuItem>

          {teamsData.map((team) => (
            <MenuItem selected={userDetails.team && ( userDetails.team === team.getId())} onClick={changeSelectedTeam(team.getId())} key={team.getId()}>
            {team.getName()}
            </MenuItem>
          ))}
      </Menu>
    </>
  }

        <Box justifyContent="right" sx={{ display: 'flex' }}>
          <Tooltip title="Account settings">
            <IconButton color="light" onClick={toggleUserMenu} size="small" sx={{ ml: 2 }}>
              <AccountCircleIcon></AccountCircleIcon>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          {...menuStyle}
          anchorEl={anchorUserMenu}
          open={openUserMenu}
          onClose={toggleUserMenu}
          onClick={toggleUserMenu}
        >
          <MenuItem onClick={() => setUserEditModal(true)}>
            {userDetails.user.email}
          </MenuItem>
          <MenuItem onClick={() => setUserEditModal(true)}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>

  </>
}

      </Box>
    </>

  );
}