import React, { Fragment, useState } from 'react';

import { TablePagination, Collapse, Avatar, ListItemAvatar, Box, Button, Typography, Switch, CircularProgress, Stack, Divider, Paper, TextField, Tooltip, Link as MLink, InputLabel, Grid, Autocomplete, NativeSelect, Select, MenuItem, InputAdornment, List, ListItem, ListItemText, Checkbox, IconButton, ListItemIcon, ListItemButton, FormControlLabel, RadioGroup, Radio, Chip, Snackbar, Alert } from '@mui/material';

import { Utilities } from '@warnerconnect/library';

import { useSnackbar } from "notistack";

import {
    ExpandLess as ExpandLessIcon,
    ExpandMore as ExpandMoreIcon,
    Info as InfoIcon,
    Folder as FolderIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    ShowChart as ShowChartIcon,
    Visibility as VisibilityIcon,
    MoreHoriz as MoreHorizIcon,
    ArrowDropUp as ArrowDropUpIcon,
    ArrowDropDown as ArrowDropDownIcon,
    Add as AddIcon
} from '@mui/icons-material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpotify, faApple, faDeezer, faYoutube, faAmazon, faVk } from '@fortawesome/free-brands-svg-icons'

import Flag from 'react-world-flags'

export default function LinkList(props) {
    const [linkData, setLinkData] = useState(props.value || [{}]);

    const addLink = (e) => {
        let updatedLinkData = [...linkData];

        updatedLinkData.push({});

        setLinkData(updatedLinkData);
    };

    const deleteLink = (index) => {
        let updatedLinkData = [...linkData];
        
        updatedLinkData.splice(index, 1);

        setLinkData(updatedLinkData);
    };

    const handleChange = (index, field) => (e) => {
        let updatedLinkData = [...linkData];

        updatedLinkData[index][field] = e.target.value;

        if( props.onChange )
        {
            props.onChange(updatedLinkData);
        }

        setLinkData(updatedLinkData);
    }
    
    return (
        <Grid container>
            {
                linkData.map( (link, linkIndex) => (
                    <Fragment key={linkIndex}>
                        <Grid item xs={5} sx={{pr: 1, mb: 1}}>
                            <TextField
                                fullWidth
                                value={link.link || ''}
                                placeholder="Link"
                                onChange={handleChange(linkIndex, 'link')}
                            />
                        </Grid>
                        <Grid item xs={5} sx={{pl: 1, mb: 1}}>
                            <TextField
                                fullWidth
                                value={link.label || ''}
                                placeholder="Label"
                                onChange={handleChange(linkIndex, 'label')}
                            />
                        </Grid>
                        <Grid item xs={2} sx={{pl: 1, mb: 1}}>
                            <IconButton onClick={() => { deleteLink(linkIndex); }} sx={{mt: 1}}><DeleteIcon /></IconButton>
                            {/*
                            {
                                ( linkIndex !== 0 ) &&
                                    <IconButton sx={{mt: 1}}><ArrowDropUpIcon /></IconButton>
                            }
                            {
                                ( linkIndex !== ( linkData.length - 1 ) ) &&
                                    <IconButton sx={{mt: 1}}><ArrowDropDownIcon /></IconButton>
                            }
                        */}
                        </Grid>
                    </Fragment>
                ))
            }

            <Button onClick={addLink} startIcon={<AddIcon />}>Add Link/Email</Button>

        </Grid>
    );
}
