import React from "react";
import ReactDOM from "react-dom";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import "./App.css";
import theme from "./Config/theme";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter as Router } from "react-router-dom";

import {
  CORE_API_ENVIRONMENT_TYPE,
  CoreAPIConfig
} from "@warnerconnect/library";
import { AuthProvider } from "./Context";

// This CoreAPIConfig environment defaults to production
// Local development
if (process.env.REACT_APP_ENV === "local") {
  CoreAPIConfig.setEnvironment(CORE_API_ENVIRONMENT_TYPE.LOCAL);
}
// Staging
else if (process.env.REACT_APP_ENV === "staging") {
  CoreAPIConfig.setEnvironment(CORE_API_ENVIRONMENT_TYPE.STAGING);
}

ReactDOM.render(
  <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <App />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  </>,
  document.getElementById("root")
);

reportWebVitals();
